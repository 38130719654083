<script setup lang="ts">
import type { DropdownItem } from '#ui/types'
import { useStorage } from '@vueuse/core'

const [inviteModalOpened, toggleInviteModal] = useToggle()
const { logoutAndClearLocalStorage } = useAuth()
const showNav = useStorage('showNav', true)

const dropdownItems = computed(() => [
  [{
    label: 'User settings',
    icon: 'regular:user',
    to: { name: 'settings-user' },
  }],
  [{
    label: 'General settings',
    icon: 'regular:gear',
    to: { name: 'settings-organization' },
  }, {
    label: 'Invite members',
    icon: 'regular:user-group',
    click: () => toggleInviteModal(),
  }],
  [{
    label: 'Sign out',
    icon: 'regular:arrow-right-from-bracket',
    click: logoutAndClearLocalStorage,
  }],
] as DropdownItem[][])
</script>

<template>
  <div class="mt-3 flex items-center justify-between px-2">
    <UDropdown
      :items="dropdownItems"
      :ui="{ width: 'w-56' }"
      :popper="{ offsetSkid: 4 }"
      data-tour="nav-header-dropdown"
    >
      <UButton
        icon="brands:sleuth"
        label="Sleuth"
        variant="ghost"
        color="white-nav"
        :ui="{ base: '!p-2', icon: { base: 'size-6' } }"
        data-tour="nav-header-dropdown-btn"
      >
        <template #trailing>
          <UIcon name="solid:angle-down" class="size-3" />
        </template>
      </UButton>
    </UDropdown>

    <div class="flex items-center">
      <UButton
        :icon="showNav ? 'solid:sidebar' : 'solid:sidebar-flip'"
        variant="ghost"
        color="white-nav"
        size="xs"
        data-action="hide-nav-button"
        class="!text-gray-500 ring-0 transition-[shadow,transform]"
        :class="{ 'translate-x-14 ring-1 ring-gray-200': !showNav }"
        :ui="{ base: '!p-2', icon: { size: { xs: 'size-[0.875rem]' } } }"
        @click="showNav = !showNav"
      />
    </div>

    <SettingsOrgInviteModal v-model="inviteModalOpened" />
  </div>
</template>
