import type { UserPermissionsType } from '~/codegen/graphql'

import { createClient } from 'villus'
import { GetUserPermissionsDocument } from './rbac.global.generated'

type Permission = keyof Pick<UserPermissionsType, 'canChangeOrg' | 'canChangeOrgConfig'>
const client = createClient({ url: '/graphql' })

export default defineNuxtRouteMiddleware(async (to, _from) => {
  const toast = useToast()
  const requiredPermission = to.meta.requirePermission as Permission | undefined

  if (requiredPermission) {
    // do this only when definePageMeta sets `requirePermission`
    const { data: userPermissions, execute } = useQuery(
      { query: GetUserPermissionsDocument, fetchOnMount: false, client },
      ({ data }) => data?.user?.permissions,
    )

    await execute()
    if (!userPermissions.value) {
      // no user
      toast.add({
        description: 'You are not signed in.',
        color: 'red',
      })
      return navigateTo({ name: 'sign-in' })
    }

    if (!userPermissions.value[requiredPermission]) {
      toast.add({
        description: 'You do not have permission to access this page.',
        color: 'red',
      })
      return navigateTo({ name: 'index' })
    }
  }
})
