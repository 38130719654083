<script setup lang="ts">
type TitleSize = 'sm' | 'md' | 'lg' | 'xl'

const props = withDefaults(
  defineProps<{
    title: string
    size?: TitleSize
    loading?: boolean
    centered?: boolean
    withoutMargin?: boolean
    truncate?: boolean
  }>(),
  {
    size: 'lg',
  },
)

const fontSizeClass = computed(
  () => ({
    sm: 'text-lg',
    md: 'text-xl',
    lg: 'text-2xl',
    xl: 'text-3xl',
  }[props.size]),
)

const centeredClass = computed(() => props.centered ? 'justify-center' : '')
const marginClass = computed(() => props.withoutMargin ? '' : 'mb-6')
const truncateClass = computed(() => props.truncate ? 'truncate' : '')
</script>

<template>
  <div
    class="flex items-center font-semibold"
    :class="[fontSizeClass, centeredClass, marginClass, truncateClass]"
  >
    <span :class="truncateClass">{{ title }}</span>
    <slot />
    <LoadingSpinner v-if="loading" class="ml-2" />
  </div>
</template>
