import { useMutationObserver } from '@vueuse/core'

export function waitForElement(selector: string, toBeRemoved = false): any {
  return new Promise((resolve) => {
    if (toBeRemoved) {
      const allElements = document.querySelectorAll(selector)
      if (allElements.length === 0) {
        resolve(null)
      }
    }
    else {
      const element = document.querySelector(selector)
      if (!toBeRemoved && element) {
        resolve(document.querySelector(selector))
      }
    }

    const { stop } = useMutationObserver(document.body, async () => {
      if (toBeRemoved) {
        const allElements = document.querySelectorAll(selector)
        if (allElements.length === 0) {
          stop()
          resolve(null)
        }
      }
      else {
        const element = document.querySelector(selector)
        if (element) {
          stop()
          resolve(element)
        }
      }
    }, {
      childList: true,
      subtree: true,
    })
  })
}

export async function waitForTransition(selector: string): Promise<void> {
  return new Promise((resolve) => {
    (async () => {
      const el = await waitForElement(selector)
      const listener = () => {
        el.removeEventListener('transitionend', listener)
        el.removeEventListener('transitioncancel', listener)
        resolve()
      }
      el.addEventListener('transitionend', listener)
      el.addEventListener('transitioncancel', listener)
    })()
  })
}
