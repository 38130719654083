<script lang="ts" setup>
import { ReviewType } from '~/codegen/graphql'
import { GetWorkspacesDocument } from '~/components/navigation/Workspace.generated'
import { CopyArtifactDocument } from './CopyModal.generated'

const props = defineProps<{
  id: string
  name: string
  type: ReviewType
  workspaceId: string
}>()
const emit = defineEmits<{ copied: [] }>()
const open = defineModel<boolean>({ required: true })
const state = reactive<{ name: string, workspaceId: string }>({ name: '', workspaceId: '' })
const toast = useToast()

const { data: workspaces, isFetching } = useQuery({
  query: GetWorkspacesDocument,
  tags: ['workspaces'],
}, ({ data }) => getListOfNodes(data?.workspaces).map(w => ({ label: w.name, value: w.id })))

const { execute: copyArtifact, isFetching: isCopying } = useMutation(CopyArtifactDocument, {
  refetchTags: [`workspace-${props.workspaceId}-${getArtifactName(props.type)}s`],
})

watch(props, () => {
  state.name = `${props.name} (copy)`
  state.workspaceId = props.workspaceId
})

async function copy() {
  const response = await copyArtifact({
    input:
      {
        fromReview: props.id,
        toWorkspace: state.workspaceId,
        newTitle: state.name,
      },
  })

  return {
    error: handleMutationError({
      errors: response.data?.copyArtifact?.errors,
      combinedError: response.error,
    }),
    data: response.data?.copyArtifact,
  }
}

async function onSubmit() {
  const { data, error } = await copy()

  if (!error) {
    state.name = ''
    state.workspaceId = ''

    toast.add({
      description: `${getArtifactName(props.type, { capitalize: true })} copied successfully`,
      actions: [{
        label: `View ${getArtifactName(props.type)}`,
        click: () => {
          if (props.type === ReviewType.Review) {
            navigateTo({
              name: 'teamspace-workspaceId-reviews-reviewId',
              params: { workspaceId: data?.review?.workspace.id ?? '', reviewId: data?.review?.id ?? '' },
            })
          }
          else {
            navigateTo({
              name: 'teamspace-workspaceId-surveys-surveyId',
              params: { workspaceId: data?.review?.workspace.id ?? '', surveyId: data?.review?.id ?? '' },
            })
          }
        },
      }],
    })

    emit('copied')
    close()
  }
}

defineShortcuts({
  meta_enter: {
    usingInput: true,
    whenever: [open],
    handler: onSubmit,
  },
})

function close() {
  open.value = false
}
</script>

<template>
  <UModal v-model="open" :ui="{ width: 'w-full sm:max-w-xl' }">
    <ModalContentWrapper :title="`Copy ${getArtifactName(props.type)}`" :scrollable-content="false" @close="close">
      <UForm id="copy-artifact-form" :state="state" @submit="onSubmit">
        <FormGroup label="Title" class="mb-4">
          <UInput v-model="state.name" autofocus />
        </FormGroup>
        <FormGroup label="Teamspace" class="mb-4">
          <SelectMenu
            v-model="state.workspaceId"
            :loading="isFetching"
            :options="workspaces"
            placeholder="Select teamspace"
            value-attribute="value"
            searchable
          />
        </FormGroup>
      </UForm>

      <template #footer>
        <UButton
          size="sm"
          variant="ghost"
          color="gray"
          @click="close"
        >
          Cancel
        </UButton>
        <UButton
          size="sm"
          type="submit"
          form="copy-artifact-form"
          :loading="isCopying"
        >
          Confirm
        </UButton>
      </template>
    </ModalContentWrapper>
  </UModal>
</template>
