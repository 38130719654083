<script lang="ts" setup>
</script>

<template>
  <nav class="z-10 flex min-h-0 max-w-full flex-1 flex-col bg-blue-50 py-2 text-nav dark:bg-gray-950">
    <NavigationHeader class="mb-4" />
    <div class="mb-4 flex items-center gap-1 px-2">
      <NavigationSearch />
      <InboxPopup />
    </div>

    <NavigationWorkspace />
    <NavigationHelp />
  </nav>
</template>
