<template>
  <UButton
    color="gray"
    variant="ghost"
    icon="light:ellipsis"
    class="group-hover/dropdown:visible group-has-[[data-headlessui-state='open']]/dropdown:!visible"
    :ui="{
      color: {
        'white-nav': {
          ghost: `group-[[data-headlessui-state='open']]/dropdown:bg-blue-80 group-[[data-headlessui-state='open']]/dropdown:dark:bg-gray-900`,
        },
        'gray': {
          ghost: `group-[[data-headlessui-state='open']]/dropdown:bg-blue-80/50 group-[[data-headlessui-state='open']]/dropdown:dark:bg-gray-800`,
        },
        'white': {
          ghost: `group-[[data-headlessui-state='open']]/dropdown:bg-white group-[[data-headlessui-state='open']]/dropdown:dark:bg-gray-900`,
        },
      },
    }"
  />
</template>
