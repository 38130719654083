<script lang="ts" setup>
const slots: Record<string, any> = useSlots()
</script>

<!-- Wrapper component for NuxtUI UFormGroup that supports inline render -->
<template>
  <UFormGroup v-bind="$attrs" :class="{ 'flex items-center justify-between gap-2': $attrs.inline }">
    <template v-for="(_, name) in slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </UFormGroup>
</template>
