<script setup lang="ts">
declare global {
  interface Window { announcekit: any }
}

const { user } = useCurrentUser()
const akWidget = useTemplateRef('akWidget')

const dropdownItems = [
  [{
    label: 'Documentation',
    to: 'https://help.pulse.sleuth.io/',
    target: '_blank',
    icon: 'regular:book',
  }, {
    label: 'What’s new',
    icon: 'regular:wave-pulse',
    click: () => (akWidget.value as any)?.open(),
    slot: 'announcekit',
  }, {
    label: 'Leave feedback',
    to: 'mailto:support@sleuth.io?subject=Pulse%20Feedback',
    icon: 'regular:message-smile',
  }],
]

const showDot = ref(false)
function setShowDot({ unread }: { unread: number }) {
  showDot.value = Boolean(unread)
}

// use window event listener to update the dot after first init
onMounted(async () => {
  waitForElement('.announcekit-widget-badge').then(() => {
    window.announcekit.on('widget-unread', setShowDot)
  })
})
</script>

<template>
  <div class="mt-auto">
    <UDropdown :items="dropdownItems" :ui="{ width: 'w-56' }" class="px-2">
      <UButton
        icon="regular:circle-question"
        variant="ghost"
        color="white-nav"
        class="!text-gray-500"
      />

      <template #announcekit="{ item }">
        <UIcon :name="item.icon" class="text-gray-400 dark:text-gray-400" />
        <span class="inline-flex items-center">
          {{ item.label }}
          <UIcon v-if="showDot" name="solid:circle" class="ml-1.5 size-2 text-[#d92d20]" /></span>
      </template>
    </UDropdown>
    <AnnounceKit
      v-if="user"
      ref="akWidget"
      widget="https://announcekit.app/widgets/v2/2r34DC"
      :user="{
        id: user.id,
        email: user.email,
        name: user.display,
      }"
      :on-widget-unread="setShowDot"
      class="pointer-events-none -left-5 -top-2"
    />
  </div>
</template>
