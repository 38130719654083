import type { CombinedError } from 'villus'
import type { ErrorType, Maybe } from '~/codegen/graphql'

const toast = useToast()

type ErrorFragment = Pick<ErrorType, '__typename' | 'messages'>

export function handleMutationError({
  errors,
  combinedError,
  permanent = false,
}: {
  errors?: Maybe<Maybe<ErrorFragment | string>[]>
  combinedError?: CombinedError | null
  permanent?: boolean
}) {
  if (Array.isArray(errors) && errors.length > 0) {
    const error = errors[0]
    if (error) {
      const message = typeof error === 'string' ? error : error.messages[0]
      toast.add({
        description: message,
        timeout: permanent ? 0 : undefined,
        color: 'red',
      })
    }
    return true
  }
  else if (combinedError) {
    return handleCombinedError(combinedError, permanent)
  }

  return false
}

export function handleCombinedError(
  combinedError: CombinedError | null,
  permanent = false,
) {
  if (!combinedError)
    return false

  toast.add({
    description: combinedError.message,
    timeout: permanent ? 0 : undefined,
    color: 'red',
  })

  return true
}
