<script setup lang="ts">
import type { User } from '~/codegen/graphql'
import { differenceInSeconds } from 'date-fns'

const { createdDate, id, seenDate } = defineProps<{
  id: string
  title: string
  link: string
  createdDate?: Date
  seenDate?: Date
  author?: Pick<User, 'display' | 'gravatar'>
  text?: string
}>()

const emit = defineEmits(['click'])

const age = computed(() => createdDate && differenceInSeconds(new Date(), createdDate))
</script>

<template>
  <InboxItemWrapper
    :link="link"
    :gravatar="author?.gravatar"
    data-action="inbox-mention"
    @click="emit('click', id)"
  >
    <div class="flex justify-between">
      <div class="font-semibold text-gray-950 dark:text-gray-50">
        {{ author?.display }} mentioned you
      </div>
      <div v-if="age" class="flex items-center text-sm text-gray-500 dark:text-gray-400">
        <UIcon v-if="!seenDate" name="solid:circle" class="mr-1 size-2 text-blue-500 dark:text-blue-400" />
        {{ formattedDuration(age) }} ago
      </div>
    </div>

    <div class="mt-1 flex items-center text-gray-900 dark:text-gray-100">
      <UIcon name="light:file" />
      <span class="ml-1">{{ title }}</span>
    </div>
    <div class="mt-2 rounded border border-gray-200 p-2 dark:border-gray-700">
      <div class="line-clamp-2" v-html="text" />
    </div>
  </InboxItemWrapper>
</template>
