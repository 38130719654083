export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('sticky-title', {
    mounted(el, binding) {
      const { mainElement } = useAppElementRefs()
      const { y } = useScroll(mainElement)
      const debouncedY = debouncedRef(y, 10)
      watch(debouncedY, () => {
        if (y.value > el.offsetTop) {
          el.style.position = 'sticky'
          el.style.top = `${binding.value}px`
          el.style.zIndex = '30'
        }
        else {
          el.style.position = ''
          el.style.top = ''
          el.style.zIndex = ''
        }
      })
    },
  })
})
