import {
  addSeconds,
  type Duration,
  format,
  formatDistanceToNowStrict,
  formatDuration,
  intervalToDuration,
} from 'date-fns'
import { Unit } from '~/codegen/graphql'

export const DATE_FORMAT = 'MMM d, yyyy'
export const SHORT_ISO_FORMAT = 'yyyy-MM-dd'
export const SHORT_DATE_FORMAT = 'MMM d'
export const ULTRA_SHORT_FORMAT = 'EEEEEE d'
export const FULL_DATE_TIME_FORMAT = `${DATE_FORMAT} h:mm a`
export const DATE_TIME_FORMAT = `${DATE_FORMAT} h:mm a`
export const SHORT_DATE_TIME_FORMAT = `${SHORT_DATE_FORMAT} 'at' h:mmaaa`
export const TIME_FORMAT = 'HH:mm'

export function toDate(dateString: string): string {
  const date = new Date(dateString)
  return format(date, DATE_FORMAT)
}

export function toShortDate(dateString: string): string {
  const date = new Date(dateString)
  return format(date, SHORT_DATE_FORMAT)
}

export function toShortDateTime(dateString: string): string {
  const date = new Date(dateString)
  return format(date, DATE_TIME_FORMAT)
}

export function toTime(dateString: string): string {
  const date = new Date(dateString)
  return format(date, TIME_FORMAT)
}

export function secondsToDuration(seconds: number): Duration {
  const baselineDate = new Date(0)
  return intervalToDuration({
    start: baselineDate,
    end: addSeconds(baselineDate, seconds),
  })
}

export function secondsToWorkDuration(seconds: number): Duration {
  const hoursPerWorkday = 8
  const workDays = Math.round(seconds / 3600 / hoursPerWorkday)

  return {
    days: workDays,
  } satisfies Duration
}

export function formattedDuration(seconds: number, options?: {
  showLessThanMinute?: boolean
  maxUnits?: number
  customNA?: string
  delimiter?: string
  unit?: Unit | ExtendedUnit
}): string {
  const {
    showLessThanMinute = false,
    maxUnits = 0,
    customNA,
    delimiter = ' ',
    unit,
  } = options || {}

  const durationFn = unit === Unit.WorkingHours ? secondsToWorkDuration : secondsToDuration

  if (seconds < 60) {
    if (showLessThanMinute) {
      if (seconds === 0) {
        return '0'
      }
      else if (seconds < 1) {
        return '< 1s'
      }
      else {
        return formatDuration(durationFn(seconds), {
          delimiter,
        }).replace(/ seconds?/, 's')
      }
    }

    return seconds >= 0 ? '< 1m' : customNA || 'N/A'
  }

  const duration = durationFn(seconds)
  let format: Array<keyof Duration> = ['years', 'months', 'weeks', 'days', 'hours', 'minutes']

  if (maxUnits > 0) {
    format = (Object.entries(duration) as Array<[keyof Duration, number]>)
      .filter(
        ([unit, amount]) =>
          amount > 0 && (unit !== 'seconds' || showLessThanMinute),
      )
      .map(([unit, _]) => unit)
      .slice(0, maxUnits)
  }

  let formattedDuration = formatDuration(duration, {
    format,
    delimiter,
  })

  if (durationFn === secondsToWorkDuration) {
    formattedDuration = formattedDuration.replace(/ day/, ' workday')
  }
  else {
    formattedDuration = shortenDuration(formattedDuration)
  }

  return formattedDuration
}

export function shortenDuration(durationString: string) {
  return durationString
    .replace(/ years?/, 'y')
    .replace(/ months?/, 'mo')
    .replace(/ weeks?/, 'w')
    .replace(/ days?/, 'd')
    .replace(/ hours?/, 'h')
    .replace(/ minutes?/, 'm')
    .replace(/ seconds?/, 's')
}

export function formattedDistanceToNow(date: Date): string {
  return shortenDuration(formatDistanceToNowStrict(date))
}
