import { useStorage } from '@vueuse/core'
import { getAuthStatus, requestLogin, requestLogout, requestRegister, requestSetNewPassword, requestStartPasswordReset, setApiRootUrl } from '../service'

const LOGIN_PATH = '/sign-in'
const DEFAULT_PATH = '/'

async function startPasswordReset(email: string) {
  const [success, errors] = await requestStartPasswordReset(email)

  if (success) {
    await navigateTo({ name: 'reset-password-request-success' })
    return null
  }
  else {
    return errors as Record<string, string[]>
  }
}

async function setNewPassword(uid: string, password: string, passwordRepeated: string) {
  const [success, errors] = await requestSetNewPassword(uid, password, passwordRepeated)

  if (success) {
    await navigateTo({ name: 'reset-password-reset-success' })
    return null
  }
  else {
    return errors as Record<string, string[]>
  }
}

export default defineNuxtPlugin(async (nuxtApp) => {
  // Skip plugin when rendering error page
  if (nuxtApp.payload.error)
    return {}

  setApiRootUrl('/_api')

  const isAuthenticated = useStorage('isAuthenticated', false)
  const currentRoute = useRoute()

  function setIsAuthenticated(newStatus: boolean) {
    isAuthenticated.value = newStatus
  }

  async function login(username: string, password: string) {
    const [success, error] = await requestLogin(username, password)

    if (success) {
      setIsAuthenticated(true)
      await navigateTo('/')
    }

    return error ? String(error) : null
  }

  async function logoutAndClearLocalStorage() {
    localStorage.removeItem('last-workspace')
    const [success, error] = await requestLogout()

    if (success)
      setIsAuthenticated(false)

    return error ? String(error) : null
  }

  async function register(password: string, invitationCode: string) {
    const [success, errors] = await requestRegister(password, invitationCode)

    if (success) {
      setIsAuthenticated(true)
      await navigateTo('/')
      return null
    }
    else {
      return errors as Record<string, string[]>
    }
  }

  const [currentAuthStatus, _error] = await getAuthStatus()
  setIsAuthenticated(currentAuthStatus)

  addRouteMiddleware(
    'auth',
    (to) => {
      if (to.meta.auth !== false && !isAuthenticated.value) {
        if (to.meta.anonymousAllowed)
          return

        const query = to.path === '/' ? {} : { next: encodeURIComponent(to.fullPath) }
        return { path: LOGIN_PATH, query }
      }
      else if (to.path === LOGIN_PATH && isAuthenticated.value) {
        return DEFAULT_PATH
      }
      else if (isAuthenticated.value && to.query.next) {
        const redirectPath = decodeURIComponent(to.query.next.toString())
        return redirectPath
      }
    },
    { global: true },
  )

  watch(isAuthenticated, async (newStatus) => {
    if (newStatus === false) {
      const cachePlugin = useGqlCache()
      cachePlugin.clearCache()

      if (currentRoute.meta.auth !== false)
        await navigateTo(LOGIN_PATH)
    }
  })

  return {
    provide: {
      auth: { login, logoutAndClearLocalStorage, register, startPasswordReset, setNewPassword, isAuthenticated },
    },
  }
})
