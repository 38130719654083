export enum IconList {
  LayerGroup = 'layer-group',
  House = 'house',
  Phone = 'phone',
  Heart = 'heart',
  Bomb = 'bomb',
  CameraRetro = 'camera-retro',
  Cloud = 'cloud',
  TruckFast = 'truck-fast',
  PenNib = 'pen-nib',
  Hippo = 'hippo',
  FaceSmile = 'face-smile',
  Paperclip = 'paperclip',
  ShieldHalved = 'shield-halved',
  CartShopping = 'cart-shopping',
  Clipboard = 'clipboard',
  AlarmClock = 'alarm-clock',
  Raygun = 'raygun',
  Car = 'car',
  Ghost = 'ghost',
  MugHot = 'mug-hot',
  RocketLaunch = 'rocket-launch',
  Pen = 'pen',
  Umbrella = 'umbrella',
  Gift = 'gift',
  Film = 'film',
  List = 'list',
  Inbox = 'inbox',
  Sparkles = 'sparkles',
  Lock = 'lock',
  Headphones = 'headphones',
  Barcode = 'barcode',
  Tag = 'tag',
  Book = 'book',
  Bookmark = 'bookmark',
  Print = 'print',
  Camera = 'camera',
  Video = 'video',
  CircleHalfStroke = 'circle-half-stroke',
  Droplet = 'droplet',
  PenToSquare = 'pen-to-square',
  CircleExclamation = 'circle-exclamation',
  Eye = 'eye',
  Fire = 'fire',
  Plane = 'plane',
  Magnet = 'magnet',
  Hand = 'hand',
  Folder = 'folder',
  MoneyBill = 'money-bill',
  GrillHot = 'grill-hot',
  Lemon = 'lemon',
  Key = 'key',
  Thumbtack = 'thumbtack',
  PaperPlane = 'paper-plane',
  Globe = 'globe',
  Truck = 'truck',
  City = 'city',
  Ticket = 'ticket',
  Tree = 'tree',
  Window = 'window',
  CameraMovie = 'camera-movie',
  PaintRoller = 'paint-roller',
  Brush = 'brush',
  Bicycle = 'bicycle',
  Flask = 'flask',
  Briefcase = 'briefcase',
  Megaphone = 'megaphone',
  Hashtag = 'hashtag',
  Compass = 'compass',
  DumpsterFire = 'dumpster-fire',
  Balloons = 'balloons',
  AddressBook = 'address-book',
  Bath = 'bath',
  Handshake = 'handshake',
  Snowflake = 'snowflake',
  EarthAmericas = 'earth-americas',
  Binoculars = 'binoculars',
  Palette = 'palette',
  Gamepad = 'gamepad',
  BusinessTime = 'business-time',
  Feather = 'feather',
  ComputerClassic = 'computer-classic',
  Sun = 'sun',
  Mobile = 'mobile',
  Computer = 'computer',
  Joystick = 'joystick',
  Bug = 'bug',
  Ship = 'ship',
  Shop = 'shop',
  Mug = 'mug',
  Landmark = 'landmark',
  PlanetRinged = 'planet-ringed',
  Campfire = 'campfire',
  MugSaucer = 'mug-saucer',
  Laptop = 'laptop',
  HandHoldingHeart = 'hand-holding-heart',
  Shirt = 'shirt',
  Anchor = 'anchor',
  Atom = 'atom',
  BagShopping = 'bag-shopping',
  Bed = 'bed',
  Gauge = 'gauge',
  UserSecret = 'user-secret',
  Stethoscope = 'stethoscope',
  Ufo = 'ufo',
  Strawberry = 'strawberry',
  AtomSimple = 'atom-simple',
  Bird = 'bird',
  Fort = 'fort',
  Crab = 'crab',
  UserBountyHunter = 'user-bounty-hunter',
  CableCar = 'cable-car',
  MountainSun = 'mountain-sun',
  BowlChopsticksNoodles = 'bowl-chopsticks-noodles',
  Moped = 'moped',
  Burrito = 'burrito',
  Bat = 'bat',
  Alien8bit = 'alien-8bit',
}

export enum IconColor {
  gray = 'text-gray-500',
  dark = 'text-gray-700 dark:text-gray-300',
  brown = 'text-[#A9800F]',
  yellow = 'text-yellow-600',
  orange = 'text-[#FE9029]',
  green = 'text-success-500',
  blue = 'text-blue-600 dark:text-blue-400',
  purple = 'text-[#7244DB]',
  pink = 'text-[#D967E1]',
  red = 'text-red-600 dark:text-red-400',
}
