<script setup lang="ts">
import type { GetToDosQuery } from './Popup.generated'
import { differenceInSeconds, format } from 'date-fns'
import { ChoreAction } from '~/codegen/graphql'

type TodoItem = GetToDosQuery['chores']['nodes'][0]

const { todo } = defineProps<{ todo: TodoItem }>()

const dueDate = computed(() => new Date(todo.dueOn))
const overdue = computed(() => dueDate.value && dueDate.value < new Date())

const differenceToDueDate = computed(() => {
  if (!dueDate.value)
    return ''
  const diff = overdue.value
    ? differenceInSeconds(new Date(), dueDate.value)
    : differenceInSeconds(dueDate.value, new Date())
  return formattedDuration(diff)
})

const formattedDueDate = computed(() =>
  dueDate.value && format(dueDate.value, SHORT_DATE_TIME_FORMAT))

const actionableItem = computed(() => ({
  [ChoreAction.PublishReview]: 'Your review',
  [ChoreAction.SubmitSectionForReview]: `Your section “${todo.subtitle}”`,
  [ChoreAction.SubmitSectionReview]: `Your review of section “${todo.subtitle}”`,
}[todo.action]))
</script>

<template>
  <InboxItemWrapper :link="todo.link" data-action="inbox-todo">
    <div class="font-semibold text-gray-950 dark:text-gray-50">
      {{ todo.title }}
    </div>
    <div class="mt-1 text-gray-900 dark:text-gray-100">
      {{ actionableItem }} is
      <span v-if="overdue" class="font-semibold text-red-500 dark:text-red-400">
        {{ differenceToDueDate }} overdue
      </span>
      <template v-else>
        due in <span class="font-semibold">{{ differenceToDueDate }}</span>
      </template>
    </div>
    <div class="mt-2 text-sm font-semibold text-gray-600 dark:text-gray-400">
      Due on: {{ formattedDueDate }}
    </div>
  </InboxItemWrapper>
</template>
