<script setup lang="ts">
import { useStorage } from '@vueuse/core'

const props = defineProps<{ workspaceId: string }>()

const { workspace } = useWorkspaceData(toRef(() => props.workspaceId))
const { topNavControls } = useAppElementRefs()
const showNav = useStorage('showNav', true)

const breadcrumbLinks = computed(() => [{
  label: workspace.value?.name ?? '',
}, {
  label: getArtifactName(useArtifactType(), { capitalize: true, plural: true }),
}])
</script>

<template>
  <div class="mx-auto flex h-14 w-full max-w-7xl items-center !px-12 pt-6 transition-all" :class="{ '!px-16': !showNav }">
    <UBreadcrumb v-if="workspace" :links="breadcrumbLinks" divider="/">
      <template #divider>
        <span class="text-gray-500">/</span>
      </template>
    </UBreadcrumb>

    <div ref="topNavControls" class="ml-auto" />
  </div>
</template>
