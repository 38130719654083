<script setup lang="ts">
import type { GetWorkspacesQuery } from './Workspace.generated'
import { WsEventType } from '~/codegen/graphql'
import { getListOfNodes } from '~/utils/relay'
import { GetWorkspacesDocument } from './Workspace.generated'

const route = useRoute('teamspace-workspaceId')
const workspaceId = computed(() => route.params.workspaceId)
const { globalWorkspace: gWorkspace } = useGlobalWorkspace()

type WorkspaceType = GetWorkspacesQuery['workspaces']['edges'][0]['node']

const { watchAndExecute } = useWebsocket()

const { data, isFetching, isDone, execute: updateWorkspaces } = useQuery({
  query: GetWorkspacesDocument,
  tags: ['workspaces'],
})

const events = [
  WsEventType.WorkspaceCreated,
  WsEventType.WorkspaceUpdated,
  WsEventType.WorkspaceRemoved,
]
watchAndExecute({ events, matcher: () => true, cb: () => updateWorkspaces({ cachePolicy: 'network-only' }) })

function workspaceToNavigationItem(workspace: WorkspaceType) {
  return {
    id: workspace.id,
    label: workspace.name,
    defaultOpen: workspaceId.value === workspace.id,
    marker: workspace.marker,
  }
}

const globalWorkspace = computed(() => {
  if (!gWorkspace.value)
    return
  return workspaceToNavigationItem(gWorkspace.value)
})

const favoriteWorkspaces = computed(() => {
  return (getListOfNodes(data.value?.workspaces)
    // TODO: this should be fixed by adding marker and other fields to favoriteWorkspaces
    .filter(w => data.value?.user?.favoriteWorkspaces.find(fw => fw.id === w.id)) ?? [])
    .map(workspaceToNavigationItem)
})

const nonGlobalWorkspaces = computed(() => {
  return getListOfNodes(data.value?.workspaces)
    .filter(w => !w.isGlobal)
    .filter(w => !favoriteWorkspaces.value.map(p => p.id).includes(w.id))
    .map(workspaceToNavigationItem)
})

const openFavoriteTeamspaces = ref(true)
const openAllTeamspaces = ref(false)
watch(favoriteWorkspaces, (newValue, oldValue) => {
  if (!isDone.value)
    return

  const currentWorkspaceFavoriteNow = newValue.some(w => w.id === workspaceId.value)
  const currentWorkspaceFavoriteBefore = oldValue.some(w => w.id === workspaceId.value)

  if (currentWorkspaceFavoriteNow && !currentWorkspaceFavoriteBefore)
    openFavoriteTeamspaces.value = true
  else if (!currentWorkspaceFavoriteNow || newValue.length === 0)
    openAllTeamspaces.value = true
})
</script>

<template>
  <Transition>
    <div v-show="!isFetching && isDone" class="flex min-h-0 flex-1 flex-col">
      <div class="flex flex-col gap-y-10 overflow-y-auto pb-4">
        <div v-if="globalWorkspace">
          <div class="ml-4 py-1 text-sm font-medium text-gray-600 dark:text-gray-400">
            Organization
          </div>
          <NavigationItems
            :workspaces="[globalWorkspace]"
            :global="true"
            :global-workspace-id="globalWorkspace?.id ?? ''"
            :favorite-workspaces="false"
          />
        </div>

        <Details
          v-if="favoriteWorkspaces.length > 0"
          v-model="openFavoriteTeamspaces"
        >
          <template #summary>
            <div class="ml-4 py-1 text-sm font-medium text-gray-600 dark:text-gray-400">
              Favorites
            </div>
          </template>
          <NavigationItems
            :workspaces="favoriteWorkspaces"
            :global="false"
            :global-workspace-id="globalWorkspace?.id ?? workspaceId ?? ''"
            :favorite-workspaces="true"
          />
        </Details>
        <Details
          v-if="nonGlobalWorkspaces.length > 0"
          v-model="openAllTeamspaces"
        >
          <template #summary>
            <div class="ml-4 py-1 text-sm font-medium text-gray-600 dark:text-gray-400">
              Teamspaces
            </div>
          </template>
          <NavigationItems
            :workspaces="nonGlobalWorkspaces"
            :global="false"
            :global-workspace-id="globalWorkspace?.id ?? workspaceId ?? ''"
            :favorite-workspaces="false"
          />
        </Details>
      </div>

      <UDivider class="mb-2" />

      <UButton
        icon="light:plus"
        data-action="new-teamspace-button"
        data-tour="new-teamspace-button"
        color="white-nav"
        label="New teamspace"
        variant="ghost"
        size="sm"
        class="m-2 justify-start px-2 font-light text-gray-900 dark:text-white"
        :ui="{ size: { sm: '!text-nav' } }"
        :to="{ name: 'teamspace-new' }"
      />
    </div>
  </Transition>
</template>
