import { ReviewType } from '~/codegen/graphql'

export default function () {
  const route = useRoute()
  switch (route.name) {
    case 'teamspace-workspaceId-reviews':
      return ReviewType.Review
    case 'teamspace-workspaceId-surveys':
      return ReviewType.Survey
    default:
      return null
  }
}
