<script lang="ts" setup>
import { capitalize } from 'remeda'
import { ReviewType } from '~/codegen/graphql'
import { GetReviewHistoryDocument } from './HistoryModal.generated'

const props = defineProps<{
  id: string
  name: string
  type: ReviewType
  workspaceId: string
  disableCopy?: boolean
}>()
const emit = defineEmits<{
  delete: [id: string, title: string]
  copy: [id: string, title: string]
}>()
const open = defineModel<boolean>({ required: true })
const artifactName = computed(() => getArtifactName(props.type))

function close() {
  open.value = false
}

const { data, isFetching } = useQuery({
  query: GetReviewHistoryDocument,
  variables: computed(() => ({ id: props.id })),
  tags: [`workspace-${props.workspaceId}-${artifactName.value}s`],
}, ({ data }) => {
  return getListOfNodes(data?.review?.chain?.chainReviews)
})

const columns = [{
  key: 'name',
  label: capitalize(artifactName.value),
}, {
  key: 'date',
  label: 'Date',
}, {
  key: 'actions',
  label: '',
}]

const rows = computed(() => {
  if (!data.value)
    return []
  return data.value.map(artifact => ({
    name: artifact.name,
    id: artifact.id,
    date: `${toShortDate(artifact.start)} – ${toShortDate(artifact.end)}`,
  }))
})
</script>

<template>
  <UModal v-model="open" :ui="{ width: 'w-full sm:max-w-3xl' }">
    <ModalContentWrapper :title="`${name} - history`" @close="close">
      <UTable
        class="max-h-[80svh]"
        :columns="columns"
        :rows="rows"
        :loading="isFetching"
        :ui="{
          wrapper: 'relative max-w-full', td: { base: '' } }"
      >
        <template #name-data="{ row }">
          <UnderlinedLink
            v-if="type === ReviewType.Review"
            :to="{
              name: 'teamspace-workspaceId-reviews-reviewId',
              params: { reviewId: row.id, workspaceId: props.workspaceId },
            }"
          >
            {{ row.name }}
          </UnderlinedLink>
          <UnderlinedLink
            v-if="type === ReviewType.Survey"
            :to="{
              name: 'teamspace-workspaceId-surveys-surveyId',
              params: { surveyId: row.id, workspaceId: props.workspaceId },
            }"
          >
            {{ row.name }}
          </UnderlinedLink>
        </template>
        <template v-if="!disableCopy" #actions-data="{ row }">
          <div class="flex justify-end">
            <UButton
              color="gray"
              icon="light:copy"
              size="sm"
              @click="emit('copy', row.id, row.name)"
            >
              Copy review
            </UButton>
          </div>
        </template>
      </UTable>
    </ModalContentWrapper>
  </UModal>
</template>
