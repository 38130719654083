<script setup lang="ts">
const props = defineProps<{
  tabs: { key: string, label: string, icon?: string }[]
  fullWidth?: boolean
  noPadding?: boolean
  scroll?: boolean
}>()

const activeTab = defineModel<string>()
const extraHeader = ref<HTMLElement | null>()

watch(() => props.tabs, () => {
  if (!props.tabs.some(tab => tab.key === activeTab.value)) {
    activeTab.value = props.tabs[0].key
  }
}, { immediate: true })
</script>

<template>
  <div class="flex min-h-px flex-1 flex-col">
    <div
      class="flex grow-0 gap-1 border-b border-gray-200 pl-1 pr-4 dark:border-gray-700"
      :class="{ 'gap-0': props.fullWidth, '!px-0': noPadding }"
    >
      <button
        v-for="tab in tabs" :key="tab.key"
        class="group/tab px-3 pt-1 text-base"
        :class="{ 'flex-1 !px-0 pt-3': props.fullWidth }"
        type="button"
        @click="activeTab = tab.key"
      >
        <div
          class="flex items-center justify-center border-b-4 border-transparent pb-1 text-center group-hover/tab:border-gray-200 group-hover/tab:dark:border-gray-700"
          :class="{ '!dark:border-primary-400 !border-primary-600': activeTab === tab.key, '!pb-3': props.fullWidth, 'text-gray-500': activeTab !== tab.key }"
        >
          <UIcon v-if="tab.icon" :name="tab.icon" class="size-4" :class="{ 'mr-1': tab.label.length }" />
          {{ tab.label }}
          <slot :name="`${tab.key}-badge`" />
        </div>
      </button>

      <div ref="extraHeader" class="ml-auto">
        <slot name="extraHeader" />
      </div>
    </div>
    <div class="flex flex-1 flex-col p-4" :class="{ '!p-0': noPadding, 'overflow-y-auto': scroll }">
      <slot v-if="$slots.tab" name="tab" :tab="tabs.find(tab => tab.key === activeTab)" :extra-header="extraHeader" />
      <template v-for="tab in tabs" v-else :key="tab.key">
        <slot v-if="tab.key === activeTab" :name="tab.key" :tab="tab" :extra-header="extraHeader" />
      </template>
    </div>
  </div>
</template>
