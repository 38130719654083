<script setup lang="ts">
import { type WorkspaceMarkerField, WorkspaceMarkerType } from '~/codegen/graphql'

const { size = 'md' } = defineProps<{
  marker: WorkspaceMarkerField
  size?: 'sm' | 'md' | 'lg'
}>()

const iconSize = computed(() => {
  if (size === 'sm')
    return 'size-3 text-sm'
  if (size === 'lg')
    return 'size-5 text-xl'

  return 'size-4 text-lg'
})
</script>

<template>
  <div class="inline-flex w-4 items-center justify-center" :class="iconSize">
    <template v-if="marker.type === WorkspaceMarkerType.Emoji">
      {{ marker.name }}
    </template>
    <template v-else>
      <UIcon
        :name="`solid:${marker.name ?? 'tag'}`" :class="[iconSize, resolveIconColor(marker.color)]"
      />
    </template>
  </div>
</template>
