<script lang="ts" setup>
const slots: Record<string, any> = useSlots()
</script>

<template>
  <UDropdown v-bind="$attrs" :data-tour="`${$attrs.name ? `${$attrs.name}-` : ''}options-dropdown`" class="group/dropdown">
    <slot>
      <OptionsButton :data-tour="`${$attrs.name ? `${$attrs.name}-` : ''}options-btn`" />
    </slot>
    <template v-for="(_, name) in slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </UDropdown>
</template>
