export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * Same scalar as `DateTime` type but if date time string does not contain timezone information
   * it will default to UTC timezone.
   * Value is specified by [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: { input: string; output: string; }
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: { input: string; output: string; }
  /** The `Decimal` scalar rounded to 2 decimal places. */
  DecimalRoundedTo2: { input: string; output: string; }
  /** The `Float` scalar rounded to 1 decimal places. `Float`: [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point). */
  FloatRoundedTo1: { input: number; output: number; }
  /** The `Float` scalar rounded to 2 decimal places. `Float`: [IEEE 754](http://en.wikipedia.org/wiki/IEEE_floating_point). */
  FloatRoundedTo2: { input: number; output: number; }
  /** The `HTMLString` scalar type represents HTML data. It is an extension of the String scalar type. */
  HTMLString: { input: string; output: string; }
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: { input: string; output: string; }
  /** Basic bucket keys are: BUGS, DEVEX, FEATURE, KTLO, SUPPORT, TECH_DEBT. But you can also create your own. */
  NamedWorkCategory: { input: string; output: string; }
  /** Used for filtering in PQL queries. Can be of one of the following types `String`, `List[String]`, `Float`, `Int`. Timedeltas are passed as `String`, their full format is `"10d:20h:3:m:9s"`, which means 10 days, 20 hours, 3 minutes, 9 seconds. But they can also be as short as 1 time-unit, example: `5h` which means 5 hours. */
  PQLFieldValue: { input: any; output: any; }
  /** The `TagOperatorType` scalar type is used for PQL queries. Valid values are `:`, `:!`, `:<`, `:>`. */
  PQLOperator: { input: string; output: string; }
  /**
   * The `Time` scalar type represents a Time value as
   * specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Time: { input: string; output: string; }
  /**
   * Create scalar that ignores normal serialization/deserialization, since
   * that will be handled by the multipart request spec
   */
  Upload: { input: any; output: any; }
};

export type AccessTokenConnection = {
  __typename?: 'AccessTokenConnection';
  edges: Array<AccessTokenNodeEdge>;
  nodes: Array<AccessTokenNode>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type AccessTokenNode = {
  __typename?: 'AccessTokenNode';
  created: Scalars['String']['output'];
  expires: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  scope: OAuthScopeType;
  token: Scalars['String']['output'];
};

export type AccessTokenNodeEdge = {
  __typename?: 'AccessTokenNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: AccessTokenNode;
};

export type AddContributorsMutationInput = {
  /** List of contributor emails to be created and added to the team */
  emails: Array<InputMaybe<Scalars['String']['input']>>;
  teamSlug?: InputMaybe<Scalars['ID']['input']>;
};

export type AddContributorsMutationPayload = {
  __typename?: 'AddContributorsMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  success: Scalars['Boolean']['output'];
};

export type AddTeamsOrUsersToScopeMutation = {
  __typename?: 'AddTeamsOrUsersToScopeMutation';
  errors?: Maybe<Array<ErrorType>>;
  instance: TeamOrUsersScoped;
};

export type AddTeamsOrUsersToScopeMutationInput = {
  id: Scalars['ID']['input'];
  /** List of TeamOrUser IDs to be added */
  teamOrUserIds: Array<Scalars['ID']['input']>;
};

export type AddUsersToScopeMutation = {
  __typename?: 'AddUsersToScopeMutation';
  errors?: Maybe<Array<ErrorType>>;
  instance: UserScoped;
};

export type AddUsersToScopeMutationInput = {
  id: Scalars['ID']['input'];
  /** List of user IDs to be added */
  users: Array<InputMaybe<Scalars['ID']['input']>>;
};

export enum AllowedLoginMethodType {
  /** Sleuth-managed username and password */
  Password = 'PASSWORD',
  /** Third-party OAuth-based authentication provider */
  Provider = 'PROVIDER'
}

export type Assessable = {
  assessment: DelayedAssessment;
};

export type Assessment = {
  __typename?: 'Assessment';
  score?: Maybe<Score>;
  scoreByAi: Score;
  summary: TextContent;
};

export type AssessmentMutationInput = {
  /** Assessable object ID. (Review or Section) */
  id: Scalars['ID']['input'];
  resetScore?: InputMaybe<Scalars['Boolean']['input']>;
  score?: InputMaybe<Score>;
  summary?: InputMaybe<Scalars['HTMLString']['input']>;
  userInputRefinement?: InputMaybe<Scalars['String']['input']>;
};

export type AtMention = {
  __typename?: 'AtMention';
  author: User;
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  link: Scalars['String']['output'];
  seenOn?: Maybe<Scalars['DateTime']['output']>;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type AtMentionConnection = {
  __typename?: 'AtMentionConnection';
  edges: Array<AtMentionEdge>;
  nodes: Array<AtMention>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type AtMentionEdge = {
  __typename?: 'AtMentionEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: AtMention;
};

export type BaseNoteGqlType = {
  msg: Scalars['String']['output'];
};

export type BatchWorkingHoursDeleteInputType = {
  userIds: Array<Scalars['String']['input']>;
};

export type BatchWorkingHoursInputType = {
  duration: Scalars['String']['input'];
  recurrence: Scalars['String']['input'];
  startTime: Scalars['Time']['input'];
  userIds: Array<Scalars['String']['input']>;
};

export type CategoriesWidgetRenderHints = {
  __typename?: 'CategoriesWidgetRenderHints';
  rawDateLabels?: Maybe<Scalars['Boolean']['output']>;
  renderPercentages?: Maybe<Scalars['Boolean']['output']>;
  xAxisLabel?: Maybe<Scalars['String']['output']>;
  yAxisLabel?: Maybe<Scalars['String']['output']>;
};

export type CategoriesWidgetValue = WidgetValue & {
  __typename?: 'CategoriesWidgetValue';
  exportLinks: Array<Maybe<ExportLink>>;
  labels?: Maybe<Array<Scalars['String']['output']>>;
  legend?: Maybe<WidgetLegend>;
  notes?: Maybe<Array<BaseNoteGqlType>>;
  renderHints?: Maybe<CategoriesWidgetRenderHints>;
  title: Scalars['String']['output'];
  type: WidgetValueFormat;
  unit: Unit;
  values: Array<Maybe<Scalars['Float']['output']>>;
  xAxisLabel?: Maybe<Scalars['String']['output']>;
  yAxisLabel?: Maybe<Scalars['String']['output']>;
};

export type CategoryDataset = {
  __typename?: 'CategoryDataset';
  category: Scalars['String']['output'];
  items: Array<Scalars['Float']['output']>;
};

export type ChangeRoleMutationInput = {
  role: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type ChangeRoleMutationPayload = {
  __typename?: 'ChangeRoleMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  success: Scalars['Boolean']['output'];
};

/** An enumeration. */
export enum ChoreAction {
  PublishReview = 'PUBLISH_REVIEW',
  SubmitSectionForReview = 'SUBMIT_SECTION_FOR_REVIEW',
  SubmitSectionReview = 'SUBMIT_SECTION_REVIEW'
}

export type ChoreConnection = {
  __typename?: 'ChoreConnection';
  edges: Array<ChoreTypeEdge>;
  nodes: Array<ChoreType>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type ChoreType = {
  __typename?: 'ChoreType';
  action: ChoreAction;
  dueOn: Scalars['DateTime']['output'];
  link: Scalars['String']['output'];
  subtitle?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type ChoreTypeEdge = {
  __typename?: 'ChoreTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: ChoreType;
};

export type ClaimEmailDomainMutation = {
  __typename?: 'ClaimEmailDomainMutation';
  success: Scalars['Boolean']['output'];
};

export type CombinedCategoriesWidgetValue = WidgetValue & {
  __typename?: 'CombinedCategoriesWidgetValue';
  exportLinks: Array<Maybe<ExportLink>>;
  labels?: Maybe<Array<Scalars['String']['output']>>;
  legend?: Maybe<WidgetLegend>;
  notes?: Maybe<Array<BaseNoteGqlType>>;
  renderHints?: Maybe<GroupedCategoriesWidgetRenderHints>;
  title: Scalars['String']['output'];
  type: WidgetValueFormat;
  unit: Unit;
  values: Array<CombinedCategoryDataset>;
  xAxisLabel?: Maybe<Scalars['String']['output']>;
  yAxisLabel?: Maybe<Scalars['String']['output']>;
};

export type CombinedCategoryDataset = {
  __typename?: 'CombinedCategoryDataset';
  category: Scalars['String']['output'];
  items: Array<Scalars['Float']['output']>;
  negative: Scalars['Boolean']['output'];
  type: CombinedChartType;
};

export enum CombinedChartType {
  /** Bar */
  Bar = 'BAR',
  /** Line */
  Line = 'LINE'
}

export type Comment = {
  __typename?: 'Comment';
  author?: Maybe<User>;
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  text: TextContent;
  updatedOn: Scalars['DateTime']['output'];
};

export type CommentConnection = {
  __typename?: 'CommentConnection';
  edges: Array<CommentEdge>;
  nodes: Array<Comment>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type CommentEdge = {
  __typename?: 'CommentEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Comment;
};

export type Commentable = {
  comment?: Maybe<Comment>;
  commentAuthors: UserConnection;
  comments: CommentConnection;
};


export type CommentableCommentArgs = {
  id: Scalars['ID']['input'];
};


export type CommentableCommentAuthorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CommentableCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CopyReviewInput = {
  fromReview: Scalars['ID']['input'];
  /** The title of the new review, defaults to the title of the previous review. */
  newTitle?: InputMaybe<Scalars['String']['input']>;
  /** The workspace to create the new review in. Defaults to the same workspace. */
  toWorkspace?: InputMaybe<Scalars['ID']['input']>;
};

/** Creates a new review that starts a new review chain. The new review will have no link to the blueprint review, except that all the widgets were copied over. */
export type CopyReviewMutation = {
  __typename?: 'CopyReviewMutation';
  errors?: Maybe<Array<ErrorType>>;
  review?: Maybe<Review>;
};

export type CreateAccessTokenInputType = {
  label: Scalars['String']['input'];
  orgSlug: Scalars['ID']['input'];
  scope: OAuthScopeType;
};

export type CreateAccessTokenMutation = {
  __typename?: 'CreateAccessTokenMutation';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  token?: Maybe<Scalars['String']['output']>;
};

export type CreateAzureIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  extraData?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAzureIntegrationMutationPayload = {
  __typename?: 'CreateAzureIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateBitbucketIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  extraData?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateBitbucketIntegrationMutationPayload = {
  __typename?: 'CreateBitbucketIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateBitbucketPipelinesIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  extraData?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateBitbucketPipelinesIntegrationMutationPayload = {
  __typename?: 'CreateBitbucketPipelinesIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateBuildkiteIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  extraData?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateBuildkiteIntegrationMutationPayload = {
  __typename?: 'CreateBuildkiteIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateCircleCiIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  extraData?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCircleCiIntegrationMutationPayload = {
  __typename?: 'CreateCircleCiIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateClubhouseIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  extraData?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateClubhouseIntegrationMutationPayload = {
  __typename?: 'CreateClubhouseIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateCommentInput = {
  reviewId?: InputMaybe<Scalars['ID']['input']>;
  sectionId?: InputMaybe<Scalars['ID']['input']>;
  text: Scalars['HTMLString']['input'];
};

export type CreateCommentMutation = {
  __typename?: 'CreateCommentMutation';
  comment?: Maybe<Comment>;
  errors?: Maybe<Array<ErrorType>>;
};

export type CreateDdIntegrationMutationInput = {
  /** Your <a href='https://app.datadoghq.com/organization-settings/application-keys' target='_blank'>generated</a> Application key */
  apiSecondaryToken: Scalars['String']['input'];
  /** Your <a href='https://app.datadoghq.com/organization-settings/api-keys' target='_blank'>generated</a> API key */
  apiToken: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  inEurope?: InputMaybe<Scalars['Boolean']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  /** URL of your Datadog instance, only required if you are using a custom subdomain. */
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDdIntegrationMutationPayload = {
  __typename?: 'CreateDDIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateGithubEnterpriseIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  extraData?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateGithubEnterpriseIntegrationMutationPayload = {
  __typename?: 'CreateGithubEnterpriseIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateGitlabIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  extraData?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateGitlabIntegrationMutationPayload = {
  __typename?: 'CreateGitlabIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  extraData?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateIntegrationMutationPayload = {
  __typename?: 'CreateIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateJenkinsIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  extraData?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateJenkinsIntegrationMutationPayload = {
  __typename?: 'CreateJenkinsIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateJiraCloudIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  extraData?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateJiraCloudIntegrationMutationPayload = {
  __typename?: 'CreateJiraCloudIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateJiraDatacenterIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  extraData?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateJiraDatacenterIntegrationMutationPayload = {
  __typename?: 'CreateJiraDatacenterIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateLegacyIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  extraData?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateLegacyIntegrationMutationPayload = {
  __typename?: 'CreateLegacyIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateLinearIntegrationMutationInput = {
  apiSecondaryToken?: InputMaybe<Scalars['String']['input']>;
  apiToken?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  extraData?: InputMaybe<Scalars['String']['input']>;
  /** Coma separated list of custom headers Sleuth will send with every request. Specify in the format: my-header=my-value,my-other-header=value2 */
  extraHeaders?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
  /** Whether only read operations will be allowed or not. If enabled, some features may be unavailable. */
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
  /** The fully qualified url of your instance. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The username of the user used to create the API token */
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CreateLinearIntegrationMutationPayload = {
  __typename?: 'CreateLinearIntegrationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  integration?: Maybe<IntegrationAuthenticationType>;
};

export type CreateNotificationInput = {
  event?: InputMaybe<NotificationEvent>;
  integrationAuthId?: InputMaybe<Scalars['ID']['input']>;
  provider: NotificationProviderType;
};

export type CreateNotificationMutation = {
  __typename?: 'CreateNotificationMutation';
  errors: Array<ErrorType>;
  notification?: Maybe<Notification>;
  success: Scalars['Boolean']['output'];
};

export type CreatePersonalTokenMutation = {
  __typename?: 'CreatePersonalTokenMutation';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  token: Scalars['String']['output'];
};

export type CreateReviewInput = {
  templateId: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type CreateReviewMutation = {
  __typename?: 'CreateReviewMutation';
  errors?: Maybe<Array<ErrorType>>;
  review?: Maybe<Review>;
};

export type CreateScopingRuleInput = {
  integrationAuthId: Scalars['ID']['input'];
  label: Scalars['String']['input'];
  parameter: Scalars['String']['input'];
  provider: ScopingRequiredProviderType;
  value: Scalars['String']['input'];
  workspaceId: Scalars['ID']['input'];
};

export type CreateScopingRuleMutation = {
  __typename?: 'CreateScopingRuleMutation';
  errors?: Maybe<Array<ErrorType>>;
  scopingRule?: Maybe<ScopingRule>;
  success: Scalars['Boolean']['output'];
};

export type CreateSectionInput = {
  hasAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
  reviewId: Scalars['ID']['input'];
};

export type CreateSectionMutation = {
  __typename?: 'CreateSectionMutation';
  errors?: Maybe<Array<ErrorType>>;
  section?: Maybe<Section>;
};

export type CreateTeamInput = {
  members: Array<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  parent?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateTeamMutation = {
  __typename?: 'CreateTeamMutation';
  errors: Array<ErrorType>;
  team?: Maybe<Team>;
};

export type CreateWidgetInput = {
  name: Scalars['String']['input'];
  parameters?: InputMaybe<Scalars['JSONString']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  renderer?: InputMaybe<FormatRenderer>;
  sectionId: Scalars['ID']['input'];
  size?: InputMaybe<WidgetSize>;
  /** All possible values are: Current month breakdown, Current quarter breakdown, Details, Manual chart, Manual stacked chart, Percentages, Period breakdown, Period vs last period trend, Period vs period last year, Previous comparison, Priorities, Proportions, Rating, Single number, Six period proportions trend, Six period trend, Survey answer list, Text, Trend. But not every widget supports all of them. Each widget has a different default. */
  valueType?: InputMaybe<Scalars['String']['input']>;
  widgetType: Scalars['String']['input'];
};

export type CreateWidgetMutation = {
  __typename?: 'CreateWidgetMutation';
  errors?: Maybe<Array<ErrorType>>;
  widget?: Maybe<Widget>;
};

export type CreateWorkspaceMutation = {
  __typename?: 'CreateWorkspaceMutation';
  errors?: Maybe<Array<ErrorType>>;
  workspace?: Maybe<Workspace>;
};

export type CreateWorkspaceMutationInput = {
  marker?: InputMaybe<WorkspaceMarkerInput>;
  name: Scalars['String']['input'];
};

export enum Currency {
  /** UAE Dirham */
  Aed = 'AED',
  /** Afghani */
  Afn = 'AFN',
  /** Lek */
  All = 'ALL',
  /** Armenian Dram */
  Amd = 'AMD',
  /** Netherlands Antillean Guilder */
  Ang = 'ANG',
  /** Kwanza */
  Aoa = 'AOA',
  /** Argentine Peso */
  Ars = 'ARS',
  /** Australian Dollar */
  Aud = 'AUD',
  /** Aruban Florin */
  Awg = 'AWG',
  /** Azerbaijanian Manat */
  Azn = 'AZN',
  /** Convertible Mark */
  Bam = 'BAM',
  /** Barbados Dollar */
  Bbd = 'BBD',
  /** Taka */
  Bdt = 'BDT',
  /** Bulgarian Lev */
  Bgn = 'BGN',
  /** Bahraini Dinar */
  Bhd = 'BHD',
  /** Burundi Franc */
  Bif = 'BIF',
  /** Bermudian Dollar */
  Bmd = 'BMD',
  /** Brunei Dollar */
  Bnd = 'BND',
  /** Boliviano */
  Bob = 'BOB',
  /** Mvdol */
  Bov = 'BOV',
  /** Brazilian Real */
  Brl = 'BRL',
  /** Bahamian Dollar */
  Bsd = 'BSD',
  /** Ngultrum */
  Btn = 'BTN',
  /** Pula */
  Bwp = 'BWP',
  /** Belarusian Ruble (BYN) */
  Byn = 'BYN',
  /** Belarusian Ruble (BYR) */
  Byr = 'BYR',
  /** Belize Dollar */
  Bzd = 'BZD',
  /** Canadian Dollar */
  Cad = 'CAD',
  /** Congolese Franc */
  Cdf = 'CDF',
  /** WIR Euro */
  Che = 'CHE',
  /** Swiss Franc */
  Chf = 'CHF',
  /** WIR Franc */
  Chw = 'CHW',
  /** Unidades de fomento */
  Clf = 'CLF',
  /** Chilean Peso */
  Clp = 'CLP',
  /** Yuan Renminbi */
  Cny = 'CNY',
  /** Colombian Peso */
  Cop = 'COP',
  /** Unidad de Valor Real */
  Cou = 'COU',
  /** Costa Rican Colon */
  Crc = 'CRC',
  /** Peso Convertible */
  Cuc = 'CUC',
  /** Cuban Peso */
  Cup = 'CUP',
  /** Cape Verde Escudo */
  Cve = 'CVE',
  /** Czech Koruna */
  Czk = 'CZK',
  /** Djibouti Franc */
  Djf = 'DJF',
  /** Danish Krone */
  Dkk = 'DKK',
  /** Dominican Peso */
  Dop = 'DOP',
  /** Algerian Dinar */
  Dzd = 'DZD',
  /** Egyptian Pound */
  Egp = 'EGP',
  /** Nakfa */
  Ern = 'ERN',
  /** Ethiopian Birr */
  Etb = 'ETB',
  /** Euro */
  Eur = 'EUR',
  /** Fiji Dollar */
  Fjd = 'FJD',
  /** Falkland Islands Pound */
  Fkp = 'FKP',
  /** Pound Sterling */
  Gbp = 'GBP',
  /** Lari */
  Gel = 'GEL',
  /** Ghana Cedi */
  Ghs = 'GHS',
  /** Gibraltar Pound */
  Gip = 'GIP',
  /** Dalasi */
  Gmd = 'GMD',
  /** Guinea Franc */
  Gnf = 'GNF',
  /** Quetzal */
  Gtq = 'GTQ',
  /** Guyana Dollar */
  Gyd = 'GYD',
  /** Hong Kong Dollar */
  Hkd = 'HKD',
  /** Lempira */
  Hnl = 'HNL',
  /** Croatian Kuna */
  Hrk = 'HRK',
  /** Gourde */
  Htg = 'HTG',
  /** Forint */
  Huf = 'HUF',
  /** Rupiah */
  Idr = 'IDR',
  /** New Israeli Sheqel */
  Ils = 'ILS',
  /** Indian Rupee */
  Inr = 'INR',
  /** Iraqi Dinar */
  Iqd = 'IQD',
  /** Iranian Rial */
  Irr = 'IRR',
  /** Iceland Krona */
  Isk = 'ISK',
  /** Jamaican Dollar */
  Jmd = 'JMD',
  /** Jordanian Dinar */
  Jod = 'JOD',
  /** Yen */
  Jpy = 'JPY',
  /** Kenyan Shilling */
  Kes = 'KES',
  /** Som */
  Kgs = 'KGS',
  /** Riel */
  Khr = 'KHR',
  /** Comoro Franc */
  Kmf = 'KMF',
  /** North Korean Won */
  Kpw = 'KPW',
  /** Won */
  Krw = 'KRW',
  /** Kuwaiti Dinar */
  Kwd = 'KWD',
  /** Cayman Islands Dollar */
  Kyd = 'KYD',
  /** Tenge */
  Kzt = 'KZT',
  /** Kip */
  Lak = 'LAK',
  /** Lebanese Pound */
  Lbp = 'LBP',
  /** Sri Lanka Rupee */
  Lkr = 'LKR',
  /** Liberian Dollar */
  Lrd = 'LRD',
  /** Loti */
  Lsl = 'LSL',
  /** Lithuanian Litas */
  Ltl = 'LTL',
  /** Latvian Lats */
  Lvl = 'LVL',
  /** Libyan Dinar */
  Lyd = 'LYD',
  /** Moroccan Dirham */
  Mad = 'MAD',
  /** Moldovan Leu */
  Mdl = 'MDL',
  /** Malagasy Ariary */
  Mga = 'MGA',
  /** Denar */
  Mkd = 'MKD',
  /** Kyat */
  Mmk = 'MMK',
  /** Tugrik */
  Mnt = 'MNT',
  /** Pataca */
  Mop = 'MOP',
  /** Ouguiya */
  Mro = 'MRO',
  /** Mauritius Rupee */
  Mur = 'MUR',
  /** Rufiyaa */
  Mvr = 'MVR',
  /** Kwacha */
  Mwk = 'MWK',
  /** Mexican Peso */
  Mxn = 'MXN',
  /** Mexican Unidad de Inversion (UDI) */
  Mxv = 'MXV',
  /** Malaysian Ringgit */
  Myr = 'MYR',
  /** Mozambique Metical */
  Mzn = 'MZN',
  /** Namibia Dollar */
  Nad = 'NAD',
  /** Naira */
  Ngn = 'NGN',
  /** Cordoba Oro */
  Nio = 'NIO',
  /** Norwegian Krone */
  Nok = 'NOK',
  /** Nepalese Rupee */
  Npr = 'NPR',
  /** New Zealand Dollar */
  Nzd = 'NZD',
  /** Rial Omani */
  Omr = 'OMR',
  /** Balboa */
  Pab = 'PAB',
  /** Nuevo Sol */
  Pen = 'PEN',
  /** Kina */
  Pgk = 'PGK',
  /** Philippine Peso */
  Php = 'PHP',
  /** Pakistan Rupee */
  Pkr = 'PKR',
  /** Zloty */
  Pln = 'PLN',
  /** Guarani */
  Pyg = 'PYG',
  /** Qatari Rial */
  Qar = 'QAR',
  /** New Romanian Leu */
  Ron = 'RON',
  /** Serbian Dinar */
  Rsd = 'RSD',
  /** Russian Ruble */
  Rub = 'RUB',
  /** Rwanda Franc */
  Rwf = 'RWF',
  /** Saudi Riyal */
  Sar = 'SAR',
  /** Solomon Islands Dollar */
  Sbd = 'SBD',
  /** Seychelles Rupee */
  Scr = 'SCR',
  /** Sudanese Pound */
  Sdg = 'SDG',
  /** Swedish Krona */
  Sek = 'SEK',
  /** Singapore Dollar */
  Sgd = 'SGD',
  /** Saint Helena Pound */
  Shp = 'SHP',
  /** Leone */
  Sll = 'SLL',
  /** Somali Shilling */
  Sos = 'SOS',
  /** Surinam Dollar */
  Srd = 'SRD',
  /** South Sudanese Pound */
  Ssp = 'SSP',
  /** Dobra */
  Std = 'STD',
  /** El Salvador Colon */
  Svc = 'SVC',
  /** Syrian Pound */
  Syp = 'SYP',
  /** Lilangeni */
  Szl = 'SZL',
  /** Baht */
  Thb = 'THB',
  /** Somoni */
  Tjs = 'TJS',
  /** Turkmenistan New Manat */
  Tmt = 'TMT',
  /** Tunisian Dinar */
  Tnd = 'TND',
  /** Pa’anga */
  Top = 'TOP',
  /** Turkish Lira */
  Try = 'TRY',
  /** Trinidad and Tobago Dollar */
  Ttd = 'TTD',
  /** New Taiwan Dollar */
  Twd = 'TWD',
  /** Tanzanian Shilling */
  Tzs = 'TZS',
  /** Hryvnia */
  Uah = 'UAH',
  /** Uganda Shilling */
  Ugx = 'UGX',
  /** US Dollar */
  Usd = 'USD',
  /** US Dollar (Next day) */
  Usn = 'USN',
  /** US Dollar (Same day) */
  Uss = 'USS',
  /** Uruguay Peso en Unidades Indexadas (URUIURUI) */
  Uyi = 'UYI',
  /** Peso Uruguayo */
  Uyu = 'UYU',
  /** Uzbekistan Sum */
  Uzs = 'UZS',
  /** Bolivar */
  Vef = 'VEF',
  /** Dong */
  Vnd = 'VND',
  /** Vatu */
  Vuv = 'VUV',
  /** Tala */
  Wst = 'WST',
  /** CFA Franc BEAC */
  Xaf = 'XAF',
  /** Silver */
  Xag = 'XAG',
  /** Gold */
  Xau = 'XAU',
  /** Bond Markets Unit European Composite Unit (EURCO) */
  Xba = 'XBA',
  /** Bond Markets Unit European Monetary Unit (E.M.U.-6) */
  Xbb = 'XBB',
  /** Bond Markets Unit European Unit of Account 9 (E.U.A.-9) */
  Xbc = 'XBC',
  /** Bond Markets Unit European Unit of Account 17 (E.U.A.-17) */
  Xbd = 'XBD',
  /** East Caribbean Dollar */
  Xcd = 'XCD',
  /** SDR (Special Drawing Right) */
  Xdr = 'XDR',
  /** UIC-Franc */
  Xfu = 'XFU',
  /** CFA Franc BCEAO */
  Xof = 'XOF',
  /** Palladium */
  Xpd = 'XPD',
  /** CFP Franc */
  Xpf = 'XPF',
  /** Platinum */
  Xpt = 'XPT',
  /** Sucre */
  Xsu = 'XSU',
  /** Codes specifically reserved for testing purposes */
  Xts = 'XTS',
  /** ADB Unit of Account */
  Xua = 'XUA',
  /** Yemeni Rial */
  Yer = 'YER',
  /** Rand */
  Zar = 'ZAR',
  /** Zambian Kwacha */
  Zmw = 'ZMW',
  /** Zimbabwe Dollar */
  Zwl = 'ZWL'
}

export type CustomizeAssessmentMutation = {
  __typename?: 'CustomizeAssessmentMutation';
  errors?: Maybe<Array<ErrorType>>;
  instance?: Maybe<Assessment>;
};

export type DateModifierNote = BaseNoteGqlType & {
  __typename?: 'DateModifierNote';
  end: Scalars['DateTime']['output'];
  endField?: Maybe<Scalars['String']['output']>;
  msg: Scalars['String']['output'];
  start: Scalars['DateTime']['output'];
  startField: Scalars['String']['output'];
};

export type Delayable = {
  error?: Maybe<WidgetError>;
  inProgress: Scalars['Boolean']['output'];
};

export type DelayedAssessment = Delayable & {
  __typename?: 'DelayedAssessment';
  error?: Maybe<WidgetError>;
  inProgress: Scalars['Boolean']['output'];
  instance?: Maybe<Assessment>;
};

export type DelayedWidgetValue = Delayable & {
  __typename?: 'DelayedWidgetValue';
  error?: Maybe<WidgetError>;
  inProgress: Scalars['Boolean']['output'];
  instance?: Maybe<WidgetValue>;
};

export type DeleteAccessTokenInputType = {
  orgSlug: Scalars['ID']['input'];
  tokenId: Scalars['ID']['input'];
};

export type DeleteAccessTokenMutation = {
  __typename?: 'DeleteAccessTokenMutation';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type DeleteBulkUserWorkingHoursMutation = {
  __typename?: 'DeleteBulkUserWorkingHoursMutation';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type DeleteCommentMutation = {
  __typename?: 'DeleteCommentMutation';
  errors?: Maybe<Array<ErrorType>>;
  success: Scalars['Boolean']['output'];
};

export type DeleteCurrentUserWorkingHoursMutation = {
  __typename?: 'DeleteCurrentUserWorkingHoursMutation';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type DeleteIdPConfigMutation = {
  __typename?: 'DeleteIdPConfigMutation';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type DeleteIntegrationAuthenticationMutationInput = {
  provider: Scalars['String']['input'];
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type DeleteIntegrationAuthenticationMutationPayload = {
  __typename?: 'DeleteIntegrationAuthenticationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  success: Scalars['Boolean']['output'];
};

export type DeleteNotificationMutation = {
  __typename?: 'DeleteNotificationMutation';
  errors: Array<ErrorType>;
  success: Scalars['Boolean']['output'];
};

export type DeleteOrganizationWorkingHoursMutation = {
  __typename?: 'DeleteOrganizationWorkingHoursMutation';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type DeletePersonalTokenMutation = {
  __typename?: 'DeletePersonalTokenMutation';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type DeleteReviewChainMutation = {
  __typename?: 'DeleteReviewChainMutation';
  errors?: Maybe<Array<ErrorType>>;
  success: Scalars['Boolean']['output'];
};

export type DeleteReviewMutation = {
  __typename?: 'DeleteReviewMutation';
  errors?: Maybe<Array<ErrorType>>;
  success: Scalars['Boolean']['output'];
};

export type DeleteScopingRuleMutation = {
  __typename?: 'DeleteScopingRuleMutation';
  errors?: Maybe<Array<ErrorType>>;
  success: Scalars['Boolean']['output'];
};

export type DeleteSectionMutation = {
  __typename?: 'DeleteSectionMutation';
  errors?: Maybe<Array<ErrorType>>;
  success: Scalars['Boolean']['output'];
};

export type DeleteTeamMutation = {
  __typename?: 'DeleteTeamMutation';
  errors: Array<ErrorType>;
  success: Scalars['Boolean']['output'];
};

export type DeleteWidgetMutation = {
  __typename?: 'DeleteWidgetMutation';
  errors?: Maybe<Array<ErrorType>>;
  success: Scalars['Boolean']['output'];
};

export type DeleteWorkspaceMutation = {
  __typename?: 'DeleteWorkspaceMutation';
  errors?: Maybe<Array<ErrorType>>;
  success: Scalars['Boolean']['output'];
};

export type DeveloperSalaryInputType = {
  annualRate: Scalars['Decimal']['input'];
  currency: Currency;
  id?: InputMaybe<Scalars['ID']['input']>;
  level: SalaryLevel;
};

export type DeveloperSalaryType = {
  __typename?: 'DeveloperSalaryType';
  annualRate: Scalars['Decimal']['output'];
  currency: Currency;
  id: Scalars['String']['output'];
  level: SalaryLevel;
};

/** An enumeration. */
export enum DigestAlgorithm {
  Sha1 = 'SHA1',
  Sha256 = 'SHA256',
  Sha384 = 'SHA384',
  Sha512 = 'SHA512'
}

export type EditCommentInput = {
  id: Scalars['ID']['input'];
  text: Scalars['HTMLString']['input'];
};

export type EditCommentMutation = {
  __typename?: 'EditCommentMutation';
  comment?: Maybe<Comment>;
  errors?: Maybe<Array<ErrorType>>;
};

export type ErrorType = {
  __typename?: 'ErrorType';
  field: Scalars['String']['output'];
  messages: Array<Scalars['String']['output']>;
};

export enum ExportFormat {
  /** XLSX */
  Xlsx = 'XLSX'
}

export type ExportLink = {
  __typename?: 'ExportLink';
  format: ExportFormat;
  url: Scalars['String']['output'];
};

export type ExternalUserType = {
  __typename?: 'ExternalUserType';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  provider: Scalars['String']['output'];
  user?: Maybe<User>;
  username?: Maybe<Scalars['String']['output']>;
};

export type ExternalUserTypeEdge = {
  __typename?: 'ExternalUserTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: ExternalUserType;
};

export type ExternalUsersConnection = {
  __typename?: 'ExternalUsersConnection';
  edges: Array<ExternalUserTypeEdge>;
  nodes: Array<ExternalUserType>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type FavoriteWorkspaceMutation = {
  __typename?: 'FavoriteWorkspaceMutation';
  errors?: Maybe<Array<ErrorType>>;
  success: Scalars['Boolean']['output'];
};

export type FavoriteWorkspaceType = {
  __typename?: 'FavoriteWorkspaceType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type FiltersModifierNote = BaseNoteGqlType & {
  __typename?: 'FiltersModifierNote';
  filters: Array<Maybe<Scalars['String']['output']>>;
  msg: Scalars['String']['output'];
};

export enum FormatRenderer {
  /** Bar chart */
  BarChart = 'BAR_CHART',
  /** Bar with line chart */
  BarWithLineChart = 'BAR_WITH_LINE_CHART',
  /** Doughnut chart */
  DoughnutChart = 'DOUGHNUT_CHART',
  /** Emoji */
  Emoji = 'EMOJI',
  /** HTML */
  Html = 'HTML',
  /** Line chart */
  LineChart = 'LINE_CHART',
  /** Percentage table */
  PercentageTable = 'PERCENTAGE_TABLE',
  /** Pie chart */
  PieChart = 'PIE_CHART',
  /** Priorities */
  Priorities = 'PRIORITIES',
  /** Radar chart */
  RadarChart = 'RADAR_CHART',
  /** Rating table */
  RatingTable = 'RATING_TABLE',
  /** Stacked bar chart */
  StackedBarChart = 'STACKED_BAR_CHART',
  /** Stacked Line chart */
  StackedLineChart = 'STACKED_LINE_CHART',
  /** Survey rating answer list */
  SurveyRatingAnswerList = 'SURVEY_RATING_ANSWER_LIST',
  /** Survey text answer list */
  SurveyTextAnswerList = 'SURVEY_TEXT_ANSWER_LIST',
  /** Traffic light */
  TrafficLight = 'TRAFFIC_LIGHT',
  /** Weather */
  Weather = 'WEATHER'
}

export type GenerateAssessmentMutation = {
  __typename?: 'GenerateAssessmentMutation';
  errors?: Maybe<Array<ErrorType>>;
};

export type GroupedCategoriesWidgetRenderHints = {
  __typename?: 'GroupedCategoriesWidgetRenderHints';
  rawDateLabels?: Maybe<Scalars['Boolean']['output']>;
  renderPercentages?: Maybe<Scalars['Boolean']['output']>;
  xAxisLabel?: Maybe<Scalars['String']['output']>;
  yAxisLabel?: Maybe<Scalars['String']['output']>;
};

export type GroupedCategoriesWidgetValue = WidgetValue & {
  __typename?: 'GroupedCategoriesWidgetValue';
  exportLinks: Array<Maybe<ExportLink>>;
  labels?: Maybe<Array<Scalars['String']['output']>>;
  legend?: Maybe<WidgetLegend>;
  legendsForPeriods?: Maybe<Array<WorkEffortLegend>>;
  notes?: Maybe<Array<BaseNoteGqlType>>;
  renderHints?: Maybe<GroupedCategoriesWidgetRenderHints>;
  title: Scalars['String']['output'];
  type: WidgetValueFormat;
  unit: Unit;
  values: Array<CategoryDataset>;
  xAxisLabel?: Maybe<Scalars['String']['output']>;
  yAxisLabel?: Maybe<Scalars['String']['output']>;
};

export type IdPConfigInputType = {
  entityId: Scalars['String']['input'];
  security?: InputMaybe<IdPSecurityConfigInputType>;
  singleLogoutService?: InputMaybe<Scalars['String']['input']>;
  singleSignOnService: Scalars['String']['input'];
  x509cert: Scalars['String']['input'];
};

export type IdPConfigType = {
  __typename?: 'IdPConfigType';
  /** This is the URL provided by the IDP that identifies the provider.It may be called a Provider Issuer or something similar.This URL must be configured for the Login to work properly. */
  entityId: Scalars['String']['output'];
  security: IdPSecurityConfigType;
  /** This is the URL provided for the logout of the user.It may be provided by the IDP but is not required */
  singleLogoutService?: Maybe<Scalars['String']['output']>;
  /** This is the URL provided by the IDP used for the login.This URL must be configured for the Login to work properly. */
  singleSignOnService: Scalars['String']['output'];
  /** This is the certificate used to verify the signature of the application and it is required. */
  x509cert: Scalars['String']['output'];
};

export type IdPSecurityConfigInputType = {
  authnRequestsSigned?: InputMaybe<Scalars['Boolean']['input']>;
  digestAlgorithm?: InputMaybe<DigestAlgorithm>;
  failOnAuthnContextMismatch?: InputMaybe<Scalars['Boolean']['input']>;
  logoutRequestSigned?: InputMaybe<Scalars['Boolean']['input']>;
  logoutResponseSigned?: InputMaybe<Scalars['Boolean']['input']>;
  nameIdEncrypted?: InputMaybe<Scalars['Boolean']['input']>;
  requestedAuthnContext?: InputMaybe<Scalars['Boolean']['input']>;
  requestedAuthnContextComparison?: InputMaybe<RequestedAuthContextComparison>;
  signMetadata?: InputMaybe<Scalars['Boolean']['input']>;
  signatureAlgorithm?: InputMaybe<SignatureAlgorithm>;
  wantAssertionsEncrypted?: InputMaybe<Scalars['Boolean']['input']>;
  wantAssertionsSigned?: InputMaybe<Scalars['Boolean']['input']>;
  wantAttributeStatement?: InputMaybe<Scalars['Boolean']['input']>;
  wantMessagesSigned?: InputMaybe<Scalars['Boolean']['input']>;
  wantNameId?: InputMaybe<Scalars['Boolean']['input']>;
  wantNameIdEncrypted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type IdPSecurityConfigType = {
  __typename?: 'IdPSecurityConfigType';
  /** Indicates whether the <samlp:AuthnRequest> messages sent by this SP will be signed.(Metadata of the SP will offer this info) */
  authnRequestsSigned: Scalars['Boolean']['output'];
  /** Algorithm that the toolkit will use on digest process. */
  digestAlgorithm: DigestAlgorithm;
  failOnAuthnContextMismatch: Scalars['Boolean']['output'];
  /** Indicates whether the <samlp:logoutRequest> messages sent by this SP will be signed. */
  logoutRequestSigned: Scalars['Boolean']['output'];
  /** Indicates whether the <samlp:logoutResponse> messages sent by this SP will be signed. */
  logoutResponseSigned: Scalars['Boolean']['output'];
  /** Indicates that the nameID of the <samlp:logoutRequest> sent by this SP will be encrypted. */
  nameIdEncrypted: Scalars['Boolean']['output'];
  /** Indicates presence of AuthContext in AuthNRequest. */
  requestedAuthnContext: Scalars['Boolean']['output'];
  requestedAuthnContextComparison: RequestedAuthContextComparison;
  /** Sign the metadata (use SP certs). */
  signMetadata: Scalars['Boolean']['output'];
  /** Algorithm that the toolkit will use on signing process. */
  signatureAlgorithm: SignatureAlgorithm;
  /** Indicates a requirement for the <saml:Assertion> elements received by this SP to be encrypted. */
  wantAssertionsEncrypted: Scalars['Boolean']['output'];
  /** Indicates a requirement for the <saml:Assertion> elements received by this SP to be signed.(Metadata of the SP will offer this info) */
  wantAssertionsSigned: Scalars['Boolean']['output'];
  /** Indicates a requirement for the AttributeStatement element. */
  wantAttributeStatement: Scalars['Boolean']['output'];
  /** Indicates a requirement for the <samlp:Response>, <samlp:LogoutRequest> and <samlp:LogoutResponse> elements received by this SP to be signed. */
  wantMessagesSigned: Scalars['Boolean']['output'];
  /** Indicates a requirement for the NameID element on the SAMLResponse received by this SP to be present. */
  wantNameId: Scalars['Boolean']['output'];
  /** Indicates a requirement for the NameID received by this SP to be encrypted. */
  wantNameIdEncrypted: Scalars['Boolean']['output'];
};

export type IgnoredIssuesNote = BaseNoteGqlType & {
  __typename?: 'IgnoredIssuesNote';
  count: Scalars['Int']['output'];
  msg: Scalars['String']['output'];
};

export type ImportIdPConfigInputType = {
  url: Scalars['String']['input'];
};

export type ImportIdPConfigMutation = {
  __typename?: 'ImportIdPConfigMutation';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  idpConfig?: Maybe<IdPConfigType>;
  success: Scalars['Boolean']['output'];
};

export enum IntegrationAuthenticationConnectionType {
  /** Uses an API key to authenticate the integration */
  ApiKey = 'API_KEY',
  /** Uses a provider-specific app to authenticate the integration */
  App = 'APP',
  /** Users a SocialAuthUser to authenticate the integration */
  Oauth = 'OAUTH'
}

export type IntegrationAuthenticationType = {
  __typename?: 'IntegrationAuthenticationType';
  apiClientName?: Maybe<Scalars['String']['output']>;
  camouflagedApiSecondaryToken: Scalars['String']['output'];
  camouflagedApiToken: Scalars['String']['output'];
  description: Scalars['String']['output'];
  disconnectPath?: Maybe<Scalars['String']['output']>;
  extraHeaders?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  org: OrganizationType;
  parameterOptions: ParameterValueOptionConnection;
  provider: Scalars['String']['output'];
  readOnly: Scalars['Boolean']['output'];
  realm?: Maybe<Scalars['String']['output']>;
  regionName?: Maybe<Scalars['String']['output']>;
  /** Be careful when changing this. Some relationships are referenced by slugs as strings. */
  slug: Scalars['String']['output'];
  type: IntegrationAuthenticationConnectionType;
  url?: Maybe<Scalars['String']['output']>;
  userLabel?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};


export type IntegrationAuthenticationTypeParameterOptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  parameter: Scalars['String']['input'];
  term?: InputMaybe<Scalars['String']['input']>;
};

export enum IntegrationProvider {
  /** Cloudwatch */
  Cloudwatch = 'CLOUDWATCH',
  /** Datadog */
  Datadog = 'DATADOG',
  /** Github */
  Github = 'GITHUB',
  /** Github Copilot */
  GithubCopilot = 'GITHUB_COPILOT',
  /** Incident Io */
  IncidentIo = 'INCIDENT_IO',
  /** Jira */
  Jira = 'JIRA',
  /** Jira Datacenter */
  JiraDatacenter = 'JIRA_DATACENTER',
  /** Linear */
  Linear = 'LINEAR',
  /** Newrelic */
  Newrelic = 'NEWRELIC',
  /** Opsgenie */
  Opsgenie = 'OPSGENIE',
  /** Pagerduty */
  Pagerduty = 'PAGERDUTY',
  /** Slack */
  Slack = 'SLACK',
  /** Sleuth Dora */
  SleuthDora = 'SLEUTH_DORA',
  /** Statuspage */
  Statuspage = 'STATUSPAGE',
  /** Zendesk */
  Zendesk = 'ZENDESK'
}

export type IntegrationProviderAuthType = {
  __typename?: 'IntegrationProviderAuthType';
  /** Is true if the integration provider supports multiple authentication connections. */
  isMulti: Scalars['Boolean']['output'];
  oauthPath?: Maybe<Scalars['String']['output']>;
  type: IntegrationAuthenticationConnectionType;
};

export type IntegrationProviderDefinitionType = {
  __typename?: 'IntegrationProviderDefinitionType';
  auth: IntegrationProviderAuthType;
  categories: Array<ProviderCategory>;
  connections: Array<IntegrationAuthenticationType>;
  integrationEnabled: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  provider: IntegrationProvider;
  scopingParameters?: Maybe<Array<ParameterValueOptionType>>;
};

export type InvitationConnection = {
  __typename?: 'InvitationConnection';
  edges: Array<InvitationTypeEdge>;
  nodes: Array<InvitationType>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type InvitationType = Node & {
  __typename?: 'InvitationType';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invitedBy: User;
  on: Scalars['DateTime']['output'];
};

export type InvitationTypeEdge = {
  __typename?: 'InvitationTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: InvitationType;
};

export type InviteMemberMutationInput = {
  email: Scalars['String']['input'];
  role: Scalars['String']['input'];
};

export type InviteMemberMutationPayload = {
  __typename?: 'InviteMemberMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  success: Scalars['Boolean']['output'];
};

export type LinkExternalUserInputType = {
  externalUserIds: Array<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};

export type LinkExternalUserMutation = {
  __typename?: 'LinkExternalUserMutation';
  errors?: Maybe<Array<ErrorType>>;
  success: Scalars['Boolean']['output'];
};

export type LoginMethodInputType = {
  provider?: InputMaybe<SocialLoginProviderType>;
  type: AllowedLoginMethodType;
};

export type MarkAtMentionAsSeenMutation = {
  __typename?: 'MarkAtMentionAsSeenMutation';
  errors?: Maybe<Array<ErrorType>>;
  success: Scalars['Boolean']['output'];
};

export type MessageType = {
  __typename?: 'MessageType';
  message: Scalars['String']['output'];
  type: MessageTypeEnum;
};

export enum MessageTypeEnum {
  Error = 'ERROR',
  Info = 'INFO',
  Warning = 'WARNING'
}

export type MoveInput = {
  id: Scalars['ID']['input'];
  newPosition: Scalars['Int']['input'];
};

export type MoveSectionMutation = {
  __typename?: 'MoveSectionMutation';
  errors?: Maybe<Array<ErrorType>>;
  section?: Maybe<Section>;
};

export type MoveWidgetMutation = {
  __typename?: 'MoveWidgetMutation';
  errors?: Maybe<Array<ErrorType>>;
  widget?: Maybe<Widget>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addContributors?: Maybe<AddContributorsMutationPayload>;
  addTeamsOrUsersToScope?: Maybe<AddTeamsOrUsersToScopeMutation>;
  addUsersToScope?: Maybe<AddUsersToScopeMutation>;
  changeMemberRole?: Maybe<ChangeRoleMutationPayload>;
  claimEmailDomain?: Maybe<ClaimEmailDomainMutation>;
  /** Creates a new review that starts a new review chain. The new review will have no link to the blueprint review, except that all the widgets were copied over. */
  copyReview?: Maybe<CopyReviewMutation>;
  createAccessToken?: Maybe<CreateAccessTokenMutation>;
  createAzureIntegration?: Maybe<CreateAzureIntegrationMutationPayload>;
  createBitbucketIntegration?: Maybe<CreateBitbucketIntegrationMutationPayload>;
  createBitbucketPipelinesIntegration?: Maybe<CreateBitbucketPipelinesIntegrationMutationPayload>;
  createBuildkiteIntegration?: Maybe<CreateBuildkiteIntegrationMutationPayload>;
  createCircleciIntegration?: Maybe<CreateCircleCiIntegrationMutationPayload>;
  createClubhouseIntegration?: Maybe<CreateClubhouseIntegrationMutationPayload>;
  createComment?: Maybe<CreateCommentMutation>;
  createDatadogIntegration?: Maybe<CreateDdIntegrationMutationPayload>;
  createGithubEnterpriseIntegration?: Maybe<CreateGithubEnterpriseIntegrationMutationPayload>;
  createGitlabIntegration?: Maybe<CreateGitlabIntegrationMutationPayload>;
  createIntegration?: Maybe<CreateIntegrationMutationPayload>;
  createJenkinsIntegration?: Maybe<CreateJenkinsIntegrationMutationPayload>;
  createJiraCloudIntegration?: Maybe<CreateJiraCloudIntegrationMutationPayload>;
  createJiraDatacenterIntegration?: Maybe<CreateJiraDatacenterIntegrationMutationPayload>;
  createLegacyIntegration?: Maybe<CreateLegacyIntegrationMutationPayload>;
  createLinearIntegration?: Maybe<CreateLinearIntegrationMutationPayload>;
  createNotification?: Maybe<CreateNotificationMutation>;
  createPersonalToken?: Maybe<CreatePersonalTokenMutation>;
  createReview?: Maybe<CreateReviewMutation>;
  createScopingRule?: Maybe<CreateScopingRuleMutation>;
  createSection?: Maybe<CreateSectionMutation>;
  createTeam?: Maybe<CreateTeamMutation>;
  createWidget?: Maybe<CreateWidgetMutation>;
  createWorkspace?: Maybe<CreateWorkspaceMutation>;
  customizeAssessment?: Maybe<CustomizeAssessmentMutation>;
  deleteAccessToken?: Maybe<DeleteAccessTokenMutation>;
  deleteBulkUserWorkingHours?: Maybe<DeleteBulkUserWorkingHoursMutation>;
  deleteComment?: Maybe<DeleteCommentMutation>;
  deleteCurrentUserWorkingHours?: Maybe<DeleteCurrentUserWorkingHoursMutation>;
  deleteIntegrationAuthentication?: Maybe<DeleteIntegrationAuthenticationMutationPayload>;
  deleteNotification?: Maybe<DeleteNotificationMutation>;
  deleteOrganizationWorkingHours?: Maybe<DeleteOrganizationWorkingHoursMutation>;
  deletePersonalToken?: Maybe<DeletePersonalTokenMutation>;
  deleteReview?: Maybe<DeleteReviewMutation>;
  deleteReviewChain?: Maybe<DeleteReviewChainMutation>;
  deleteSamlIdpConfig?: Maybe<DeleteIdPConfigMutation>;
  deleteScopingRule?: Maybe<DeleteScopingRuleMutation>;
  deleteSection?: Maybe<DeleteSectionMutation>;
  deleteTeam?: Maybe<DeleteTeamMutation>;
  deleteWidget?: Maybe<DeleteWidgetMutation>;
  deleteWorkspace?: Maybe<DeleteWorkspaceMutation>;
  editComment?: Maybe<EditCommentMutation>;
  favoriteWorkspace?: Maybe<FavoriteWorkspaceMutation>;
  generateAssessment?: Maybe<GenerateAssessmentMutation>;
  importSamlIdpConfigFromUrl?: Maybe<ImportIdPConfigMutation>;
  inviteMember?: Maybe<InviteMemberMutationPayload>;
  linkExternalUsers?: Maybe<LinkExternalUserMutation>;
  markAtMentionAsSeen?: Maybe<MarkAtMentionAsSeenMutation>;
  markSeen?: Maybe<SeenEventType>;
  moveSection?: Maybe<MoveSectionMutation>;
  moveWidget?: Maybe<MoveWidgetMutation>;
  removeTeamMember?: Maybe<RemoveTeamMemberMutation>;
  removeTeamsOrUsersFromScope?: Maybe<RemoveTeamsOrUsersFromScopeMutation>;
  removeUsersFromScope?: Maybe<RemoveUsersFromScopeMutation>;
  /** Create a new review in this chain of reviews. The new review will link to the previous review and the previous review will link to the new review. */
  repeatReview?: Maybe<RepeatReviewMutation>;
  resendEmailConfirmation?: Maybe<ResendEmailConfirmationMutation>;
  resendInvitation?: Maybe<ResendInvitationMutationPayload>;
  revokeInvitation?: Maybe<RevokeInvitationMutationPayload>;
  setBulkUserWorkingHours?: Maybe<SetBulkUserWorkingHoursMutation>;
  setContributorEmail?: Maybe<SetContributorEmailMutation>;
  setCurrentUserWorkingHours?: Maybe<SetCurrentUserWorkingHoursMutation>;
  setDeveloperSalary?: Maybe<SetDeveloperSalaryMutation>;
  /** NOTE: If the list of filters is empty, we will return `workPlanning: null`. */
  setNamedWorkCategory?: Maybe<SetNamedWorkCategoryMutation>;
  /** NOTE: If the list of filters is empty, we will return `workPlanning: null`. */
  setNamedWorkPlanning?: Maybe<SetNamedWorkPlanningMutation>;
  setOrganizationWorkingHours?: Maybe<SetOrganizationWorkingHoursMutation>;
  setReviewStatus?: Maybe<SetReviewStatusMutation>;
  setSamlIdpConfig?: Maybe<SetIdPConfigMutation>;
  setScopingRules?: Maybe<SetScopingRulesMutation>;
  setSectionStatus?: Maybe<SetSectionStatusMutation>;
  setTeamsSyncStatus?: Maybe<TeamsSyncStatusMutation>;
  shareReview?: Maybe<ShareReviewMutation>;
  toggleLLMFeatures?: Maybe<ToggleLlmFeaturesMutation>;
  trackView?: Maybe<TrackViewMutation>;
  /** @deprecated Use `triggerRemoteObjectSync` mutation instead. */
  triggerIssueStatusesSync?: Maybe<TriggerIssueStatusesSyncMutation>;
  triggerRemoteObjectSync?: Maybe<TriggerRemoteObjectSyncMutation>;
  triggerTeamsSync?: Maybe<TriggerTeamsSyncMutation>;
  unfavoriteWorkspace?: Maybe<UnfavoriteWorkspaceMutation>;
  unlinkExternalUsers?: Maybe<UnlinkExternalUserMutation>;
  unshareReview?: Maybe<UnshareReviewMutation>;
  updateOrganization?: Maybe<UpdateOrganizationMutation>;
  updateReview?: Maybe<UpdateReviewMutation>;
  updateSection?: Maybe<UpdateSectionMutation>;
  updateTeam?: Maybe<UpdateTeamMutation>;
  updateUser?: Maybe<UpdateUserMutation>;
  updateWidget?: Maybe<UpdateWidgetMutation>;
  updateWorkspace?: Maybe<UpdateWorkspaceMutation>;
  upload?: Maybe<UploadMutation>;
};


export type MutationAddContributorsArgs = {
  input: AddContributorsMutationInput;
};


export type MutationAddTeamsOrUsersToScopeArgs = {
  input: AddTeamsOrUsersToScopeMutationInput;
};


export type MutationAddUsersToScopeArgs = {
  input: AddUsersToScopeMutationInput;
};


export type MutationChangeMemberRoleArgs = {
  input: ChangeRoleMutationInput;
};


export type MutationClaimEmailDomainArgs = {
  enable: Scalars['Boolean']['input'];
  orgSlug: Scalars['ID']['input'];
};


export type MutationCopyReviewArgs = {
  input: CopyReviewInput;
};


export type MutationCreateAccessTokenArgs = {
  input: CreateAccessTokenInputType;
};


export type MutationCreateAzureIntegrationArgs = {
  input: CreateAzureIntegrationMutationInput;
};


export type MutationCreateBitbucketIntegrationArgs = {
  input: CreateBitbucketIntegrationMutationInput;
};


export type MutationCreateBitbucketPipelinesIntegrationArgs = {
  input: CreateBitbucketPipelinesIntegrationMutationInput;
};


export type MutationCreateBuildkiteIntegrationArgs = {
  input: CreateBuildkiteIntegrationMutationInput;
};


export type MutationCreateCircleciIntegrationArgs = {
  input: CreateCircleCiIntegrationMutationInput;
};


export type MutationCreateClubhouseIntegrationArgs = {
  input: CreateClubhouseIntegrationMutationInput;
};


export type MutationCreateCommentArgs = {
  input: CreateCommentInput;
};


export type MutationCreateDatadogIntegrationArgs = {
  input: CreateDdIntegrationMutationInput;
};


export type MutationCreateGithubEnterpriseIntegrationArgs = {
  input: CreateGithubEnterpriseIntegrationMutationInput;
};


export type MutationCreateGitlabIntegrationArgs = {
  input: CreateGitlabIntegrationMutationInput;
};


export type MutationCreateIntegrationArgs = {
  input: CreateIntegrationMutationInput;
};


export type MutationCreateJenkinsIntegrationArgs = {
  input: CreateJenkinsIntegrationMutationInput;
};


export type MutationCreateJiraCloudIntegrationArgs = {
  input: CreateJiraCloudIntegrationMutationInput;
};


export type MutationCreateJiraDatacenterIntegrationArgs = {
  input: CreateJiraDatacenterIntegrationMutationInput;
};


export type MutationCreateLegacyIntegrationArgs = {
  input: CreateLegacyIntegrationMutationInput;
};


export type MutationCreateLinearIntegrationArgs = {
  input: CreateLinearIntegrationMutationInput;
};


export type MutationCreateNotificationArgs = {
  input: CreateNotificationInput;
};


export type MutationCreatePersonalTokenArgs = {
  label: Scalars['String']['input'];
};


export type MutationCreateReviewArgs = {
  input: CreateReviewInput;
};


export type MutationCreateScopingRuleArgs = {
  input: CreateScopingRuleInput;
};


export type MutationCreateSectionArgs = {
  input: CreateSectionInput;
};


export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};


export type MutationCreateWidgetArgs = {
  input: CreateWidgetInput;
};


export type MutationCreateWorkspaceArgs = {
  input: CreateWorkspaceMutationInput;
};


export type MutationCustomizeAssessmentArgs = {
  input: AssessmentMutationInput;
};


export type MutationDeleteAccessTokenArgs = {
  input: DeleteAccessTokenInputType;
};


export type MutationDeleteBulkUserWorkingHoursArgs = {
  input: BatchWorkingHoursDeleteInputType;
};


export type MutationDeleteCommentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteIntegrationAuthenticationArgs = {
  input: DeleteIntegrationAuthenticationMutationInput;
};


export type MutationDeleteNotificationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePersonalTokenArgs = {
  tokenId: Scalars['ID']['input'];
};


export type MutationDeleteReviewArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteReviewChainArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteScopingRuleArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSectionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTeamArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteWidgetArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteWorkspaceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationEditCommentArgs = {
  input: EditCommentInput;
};


export type MutationFavoriteWorkspaceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationGenerateAssessmentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationImportSamlIdpConfigFromUrlArgs = {
  input: ImportIdPConfigInputType;
};


export type MutationInviteMemberArgs = {
  input: InviteMemberMutationInput;
};


export type MutationLinkExternalUsersArgs = {
  input: LinkExternalUserInputType;
};


export type MutationMarkAtMentionAsSeenArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMarkSeenArgs = {
  name: Scalars['String']['input'];
  orgSlug?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationMoveSectionArgs = {
  input: MoveInput;
};


export type MutationMoveWidgetArgs = {
  input: MoveInput;
};


export type MutationRemoveTeamMemberArgs = {
  input: RemoveTeamMemberInput;
};


export type MutationRemoveTeamsOrUsersFromScopeArgs = {
  input: RemoveTeamsOrUsersFromScopeMutationInput;
};


export type MutationRemoveUsersFromScopeArgs = {
  input: RemoveUsersFromScopeMutationInput;
};


export type MutationRepeatReviewArgs = {
  input: RepeatReviewInput;
};


export type MutationResendInvitationArgs = {
  input: ResendInvitationMutationInput;
};


export type MutationRevokeInvitationArgs = {
  input: RevokeInvitationMutationInput;
};


export type MutationSetBulkUserWorkingHoursArgs = {
  input: BatchWorkingHoursInputType;
};


export type MutationSetContributorEmailArgs = {
  input: SetContributorEmailInput;
};


export type MutationSetCurrentUserWorkingHoursArgs = {
  input: WorkingHoursInputType;
};


export type MutationSetDeveloperSalaryArgs = {
  input: DeveloperSalaryInputType;
};


export type MutationSetNamedWorkCategoryArgs = {
  input: NamedWorkCategoryInputType;
};


export type MutationSetNamedWorkPlanningArgs = {
  input: NamedWorkPlanningInputType;
};


export type MutationSetOrganizationWorkingHoursArgs = {
  input: WorkingHoursInputType;
};


export type MutationSetReviewStatusArgs = {
  input: SetReviewStatusInput;
};


export type MutationSetSamlIdpConfigArgs = {
  input: IdPConfigInputType;
};


export type MutationSetScopingRulesArgs = {
  input: SetScopingRulesInput;
};


export type MutationSetSectionStatusArgs = {
  input: SetSectionStatusInput;
};


export type MutationSetTeamsSyncStatusArgs = {
  input: TeamsSyncInputType;
};


export type MutationShareReviewArgs = {
  reviewId: Scalars['ID']['input'];
};


export type MutationToggleLlmFeaturesArgs = {
  enabled: Scalars['Boolean']['input'];
};


export type MutationTrackViewArgs = {
  targetId: Scalars['ID']['input'];
  targetType: ViewTargetType;
};


export type MutationTriggerIssueStatusesSyncArgs = {
  input: TriggerIssueStatusesSyncInputType;
};


export type MutationTriggerRemoteObjectSyncArgs = {
  input: TriggerRemoteObjectSyncInputType;
};


export type MutationTriggerTeamsSyncArgs = {
  input: TriggerTeamsSyncInputType;
};


export type MutationUnfavoriteWorkspaceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUnlinkExternalUsersArgs = {
  input: LinkExternalUserInputType;
};


export type MutationUnshareReviewArgs = {
  reviewId: Scalars['ID']['input'];
};


export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInputType;
};


export type MutationUpdateReviewArgs = {
  input: UpdateReviewInput;
};


export type MutationUpdateSectionArgs = {
  input: UpdateSectionInput;
};


export type MutationUpdateTeamArgs = {
  input: UpdateTeamInput;
};


export type MutationUpdateUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateWidgetArgs = {
  input: UpdateWidgetInput;
};


export type MutationUpdateWorkspaceArgs = {
  input: UpdateWorkspaceMutationInput;
};


export type MutationUploadArgs = {
  files: Array<Scalars['Upload']['input']>;
};

export type NameAndSlugSearchOptionType = {
  __typename?: 'NameAndSlugSearchOptionType';
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type NamedWorkCategoryGqlType = {
  __typename?: 'NamedWorkCategoryGqlType';
  bucketKey: Scalars['NamedWorkCategory']['output'];
  id: Scalars['String']['output'];
  pql: Scalars['String']['output'];
  pqlFilters: Array<PqlFilter>;
  workspace: Workspace;
};

export type NamedWorkCategoryInputType = {
  bucketKey: Scalars['NamedWorkCategory']['input'];
  pqlFilterGroups: Array<PqlFilterGroupsGqlInput>;
  workspaceId: Scalars['ID']['input'];
};

export enum NamedWorkCategoryType {
  /** Bugs */
  Bugs = 'BUGS',
  /** DevEx and Internal Improvements */
  Devex = 'DEVEX',
  /** Feature and Enhancements */
  Feature = 'FEATURE',
  /** Keep The Lights On */
  Ktlo = 'KTLO',
  /** Support */
  Support = 'SUPPORT',
  /** Tech Debt */
  TechDebt = 'TECH_DEBT'
}

export type NamedWorkPlanningGqlType = {
  __typename?: 'NamedWorkPlanningGqlType';
  bucketKey: NamedWorkPlanningType;
  id: Scalars['String']['output'];
  pql: Scalars['String']['output'];
  pqlFilters: Array<PqlFilter>;
  workspace: Workspace;
};

export type NamedWorkPlanningInputType = {
  bucketKey: NamedWorkPlanningType;
  id?: InputMaybe<Scalars['ID']['input']>;
  pqlFilterGroups: Array<PqlFilterGroupsGqlInput>;
  workspaceId: Scalars['ID']['input'];
};

export enum NamedWorkPlanningType {
  /** Planned Work */
  PlannedWork = 'PLANNED_WORK',
  /** Unplanned Work */
  UnplannedWork = 'UNPLANNED_WORK'
}

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  id: Scalars['ID']['output'];
};

export type Note = BaseNoteGqlType & {
  __typename?: 'Note';
  msg: Scalars['String']['output'];
};

export type Notification = {
  __typename?: 'Notification';
  createdOn: Scalars['DateTime']['output'];
  event: NotificationEvent;
  id: Scalars['ID']['output'];
  integrationAuth?: Maybe<IntegrationAuthenticationType>;
  modifiedOn: Scalars['DateTime']['output'];
  provider: NotificationProviderType;
  recipient?: Maybe<User>;
};

export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  edges: Array<NotificationEdge>;
  nodes: Array<Notification>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type NotificationEdge = {
  __typename?: 'NotificationEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Notification;
};

export enum NotificationEvent {
  /** All Sections Submitted */
  AllSectionsSubmitted = 'ALL_SECTIONS_SUBMITTED',
  /** User Mention */
  AtMention = 'AT_MENTION',
  /** Review Due Date Changed */
  ReviewDueDateChanged = 'REVIEW_DUE_DATE_CHANGED',
  /** Review Overdue */
  ReviewOverdue = 'REVIEW_OVERDUE',
  /** Review Published */
  ReviewPublished = 'REVIEW_PUBLISHED',
  /** Review Scope Changed */
  ReviewScopeChanged = 'REVIEW_SCOPE_CHANGED',
  /** Review Stopped */
  ReviewStopped = 'REVIEW_STOPPED',
  /** Section Approved */
  SectionApproved = 'SECTION_APPROVED',
  /** Section Assigned */
  SectionAssigned = 'SECTION_ASSIGNED',
  /** Section Commented On */
  SectionCommentedOn = 'SECTION_COMMENTED_ON',
  /** Section Deleted */
  SectionDeleted = 'SECTION_DELETED',
  /** Section Rejected */
  SectionRejected = 'SECTION_REJECTED',
  /** Section Submitted */
  SectionSubmitted = 'SECTION_SUBMITTED',
  /** Section Unassigned */
  SectionUnassigned = 'SECTION_UNASSIGNED',
  /** Your Review Section Changed */
  YourReviewSectionChanged = 'YOUR_REVIEW_SECTION_CHANGED',
  /** Your Review State Changed */
  YourReviewStateChanged = 'YOUR_REVIEW_STATE_CHANGED',
  /** Your Section Submitted */
  YourSectionSubmitted = 'YOUR_SECTION_SUBMITTED'
}

export enum NotificationProviderType {
  /** Email */
  Email = 'EMAIL',
  /** Slack */
  Slack = 'SLACK'
}

export type NumberWidgetValue = WidgetValue & {
  __typename?: 'NumberWidgetValue';
  exportLinks: Array<Maybe<ExportLink>>;
  legend?: Maybe<WidgetLegend>;
  notes?: Maybe<Array<BaseNoteGqlType>>;
  number?: Maybe<Scalars['Float']['output']>;
  summary?: Maybe<Scalars['String']['output']>;
  type: WidgetValueFormat;
  unit: Unit;
};

export enum OAuthScopeType {
  All = 'ALL',
  /** Register deploy */
  RegisterDeploy = 'REGISTER_DEPLOY'
}

export type OrgAllowedLoginMethodType = {
  __typename?: 'OrgAllowedLoginMethodType';
  enabled: Scalars['Boolean']['output'];
  provider?: Maybe<SocialLoginProviderType>;
  type: AllowedLoginMethodType;
};

export type OrganizationType = TeamOrUsersScoped & {
  __typename?: 'OrganizationType';
  accessTokens: AccessTokenConnection;
  addressCity?: Maybe<Scalars['String']['output']>;
  addressCountry?: Maybe<Scalars['String']['output']>;
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  addressPostalCode?: Maybe<Scalars['String']['output']>;
  addressState?: Maybe<Scalars['String']['output']>;
  allowedLoginMethods: Array<OrgAllowedLoginMethodType>;
  /** @deprecated Use `accessTokens` field instead. */
  apiKey: Scalars['String']['output'];
  /** Default role to assign to users signing up with SSO. Only supports MEMBER, DEV and ADMIN. */
  defaultRole: Role;
  description: Scalars['String']['output'];
  developerSalaries: Array<DeveloperSalaryType>;
  emailDomain?: Maybe<Scalars['String']['output']>;
  enterprise: Scalars['Boolean']['output'];
  externalUsers: ExternalUsersConnection;
  flagEnabled: Scalars['Boolean']['output'];
  free: Scalars['Boolean']['output'];
  isActive: Scalars['Boolean']['output'];
  llmFeaturesEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  owner?: Maybe<User>;
  pendingInvites: InvitationConnection;
  samlIdpConfig?: Maybe<IdPConfigType>;
  slug: Scalars['String']['output'];
  syncStatuses: SyncStatusesConnection;
  team: Team;
  teams: TeamsConnection;
  teamsOrUsers: TeamOrUsersConnection;
  teamsSyncStatuses: Array<TeamsSyncStatusType>;
  timezone?: Maybe<Scalars['String']['output']>;
  trial: Scalars['Boolean']['output'];
  trialDaysRemaining: Scalars['Int']['output'];
  url: Scalars['String']['output'];
  users: UserConnection;
  /** EXPERIMENTAL FIELD, can change at any time. */
  workingHours?: Maybe<WorkingHoursType>;
};


export type OrganizationTypeAccessTokensArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganizationTypeExternalUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  excludeLinked?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
};


export type OrganizationTypeFlagEnabledArgs = {
  flagName: Scalars['String']['input'];
};


export type OrganizationTypePendingInvitesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrganizationTypeSyncStatusesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  integrationAuthSlug?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  provider?: InputMaybe<Provider>;
  remoteObjectType: RemoteObjectSyncType;
};


export type OrganizationTypeTeamArgs = {
  id: Scalars['ID']['input'];
};


export type OrganizationTypeTeamsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  childless?: InputMaybe<Scalars['Boolean']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  parent?: InputMaybe<TeamParentInputType>;
  term?: InputMaybe<Scalars['String']['input']>;
};


export type OrganizationTypeTeamsOrUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  excludeId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
};


export type OrganizationTypeUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  excludeTeamSlug?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  term: Scalars['String']['input'];
  userRole?: InputMaybe<Role>;
  userType?: InputMaybe<UsersType>;
};

/** Single filter for use in PQL queries. */
export type PqlFilter = {
  __typename?: 'PQLFilter';
  operator: Scalars['PQLOperator']['output'];
  tagName: Scalars['String']['output'];
  tagValue: Scalars['PQLFieldValue']['output'];
};

export type PqlFilterGroupsGqlInput = {
  pqlFilters: Array<PqlFilterInput>;
};

/** Single filter for use in PQL queries. */
export type PqlFilterInput = {
  operator: Scalars['PQLOperator']['input'];
  tagName: Scalars['String']['input'];
  tagValue: Scalars['PQLFieldValue']['input'];
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type ParameterValueOptionConnection = {
  __typename?: 'ParameterValueOptionConnection';
  edges: Array<ParameterValueOptionTypeEdge>;
  nodes: Array<ParameterValueOptionType>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type ParameterValueOptionType = {
  __typename?: 'ParameterValueOptionType';
  label: Scalars['String']['output'];
  /** This field holds additional metadata for the option. It is different for different parameter types. */
  meta?: Maybe<Scalars['JSONString']['output']>;
  value: Scalars['String']['output'];
};

export type ParameterValueOptionTypeEdge = {
  __typename?: 'ParameterValueOptionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: ParameterValueOptionType;
};

export type PercentageCategoriesWidgetValue = WidgetValue & {
  __typename?: 'PercentageCategoriesWidgetValue';
  exportLinks: Array<Maybe<ExportLink>>;
  labels?: Maybe<Array<Scalars['String']['output']>>;
  legend?: Maybe<WidgetLegend>;
  notes?: Maybe<Array<BaseNoteGqlType>>;
  renderHints?: Maybe<CategoriesWidgetRenderHints>;
  title: Scalars['String']['output'];
  type: WidgetValueFormat;
  unit: Unit;
  values: Array<Maybe<Scalars['Float']['output']>>;
  xAxisLabel?: Maybe<Scalars['String']['output']>;
  yAxisLabel?: Maybe<Scalars['String']['output']>;
};

export type PqlResponse = {
  __typename?: 'PqlResponse';
  filters: Array<Maybe<QueryFilter>>;
  floatValue?: Maybe<Scalars['Float']['output']>;
  intValue?: Maybe<Scalars['Float']['output']>;
  listValue?: Maybe<Array<Scalars['String']['output']>>;
  strValue?: Maybe<Scalars['String']['output']>;
};

export type PrioritiesWidgetValue = WidgetValue & {
  __typename?: 'PrioritiesWidgetValue';
  exportLinks: Array<Maybe<ExportLink>>;
  legend?: Maybe<WidgetLegend>;
  nextCyclePriorities: Array<PriorityWidgetValueData>;
  notes?: Maybe<Array<BaseNoteGqlType>>;
  priorities: Array<PriorityWidgetValueData>;
  type: WidgetValueFormat;
};

export enum PriorityStatus {
  /** 🚫 Blocked */
  Blocked = 'BLOCKED',
  /** 🙅‍♀️ Deprioritized */
  Deprioritized = 'DEPRIORITIZED',
  /** ✅ Done */
  Done = 'DONE',
  /** ➕ Newly added */
  NewlyAdded = 'NEWLY_ADDED',
  /** ⚠️ Off track */
  OffTrack = 'OFF_TRACK',
  /** 👍 On track */
  OnTrack = 'ON_TRACK'
}

export type PriorityWidgetValueData = {
  __typename?: 'PriorityWidgetValueData';
  priority: TextContent;
  status?: Maybe<PriorityStatus>;
};

export enum Provider {
  /** AppDynamics */
  Appdynamics = 'APPDYNAMICS',
  /** AWS */
  Aws = 'AWS',
  /** Azure DevOps */
  Azure = 'AZURE',
  /** Bitbucket */
  Bitbucket = 'BITBUCKET',
  /** Bitbucket Pipelines */
  BitbucketPipelines = 'BITBUCKET_PIPELINES',
  /** Blameless */
  Blameless = 'BLAMELESS',
  /** Bugsnag */
  Bugsnag = 'BUGSNAG',
  /** Buildkite */
  Buildkite = 'BUILDKITE',
  /** CircleCI */
  Circleci = 'CIRCLECI',
  /** Amazon CloudWatch */
  Cloudwatch = 'CLOUDWATCH',
  /** Shortcut */
  Clubhouse = 'CLUBHOUSE',
  /** CodeDeploy */
  Codedeploy = 'CODEDEPLOY',
  /** Custom - any git repository */
  CustomGit = 'CUSTOM_GIT',
  /** Custom Incident - user provided */
  CustomIncident = 'CUSTOM_INCIDENT',
  /** Custom Metric - user provided */
  CustomMetric = 'CUSTOM_METRIC',
  /** Datadog */
  Datadog = 'DATADOG',
  /** Email */
  Email = 'EMAIL',
  /** Fake Build */
  Fakebuild = 'FAKEBUILD',
  /** Fake Flag */
  Fakeflag = 'FAKEFLAG',
  /** Fake Git */
  Fakegit = 'FAKEGIT',
  /** FireHydrant */
  Firehydrant = 'FIREHYDRANT',
  /** GitHub */
  Github = 'GITHUB',
  /** Sleuth Automations for GitHub */
  GithubAutomations = 'GITHUB_AUTOMATIONS',
  /** GitHub Copilot */
  GithubCopilot = 'GITHUB_COPILOT',
  /** GitHub Enterprise Server */
  GithubEnterprise = 'GITHUB_ENTERPRISE',
  /** GitLab */
  Gitlab = 'GITLAB',
  /** Google */
  Google = 'GOOGLE',
  /** Honeybadger */
  Honeybadger = 'HONEYBADGER',
  /** Incident.io */
  IncidentIo = 'INCIDENT_IO',
  /** Jenkins */
  Jenkins = 'JENKINS',
  /** Jira Cloud */
  Jira = 'JIRA',
  /** Jira Data Center */
  JiraDatacenter = 'JIRA_DATACENTER',
  /** LaunchDarkly */
  Launchdarkly = 'LAUNCHDARKLY',
  /** Linear */
  Linear = 'LINEAR',
  /** Microsoft */
  Microsoft = 'MICROSOFT',
  /** Microsoft Teams */
  MicrosoftTeams = 'MICROSOFT_TEAMS',
  /** New Relic */
  Newrelic = 'NEWRELIC',
  /** Opsgenie */
  Opsgenie = 'OPSGENIE',
  /** PagerDuty */
  Pagerduty = 'PAGERDUTY',
  /** Rollbar */
  Rollbar = 'ROLLBAR',
  /** Rootly */
  Rootly = 'ROOTLY',
  /** SAML */
  Saml = 'SAML',
  /** Sentry Cloud */
  Sentry = 'SENTRY',
  /** Sentry Self-Hosted */
  SentrySelfHosted = 'SENTRY_SELF_HOSTED',
  /** ServiceNow */
  Servicenow = 'SERVICENOW',
  /** Splunk Observability Cloud */
  Signalfx = 'SIGNALFX',
  /** Slack */
  Slack = 'SLACK',
  /** Sleuth DORA */
  SleuthDora = 'SLEUTH_DORA',
  /** Statuspage */
  Statuspage = 'STATUSPAGE',
  /** Zendesk */
  Zendesk = 'ZENDESK'
}

export enum ProviderCategory {
  Chat = 'CHAT',
  Code = 'CODE',
  Incident = 'INCIDENT',
  Issue = 'ISSUE',
  Metric = 'METRIC',
  Support = 'SUPPORT'
}

export type Query = {
  __typename?: 'Query';
  baseurl: Scalars['String']['output'];
  chores: ChoreConnection;
  flashMessages: Array<MessageType>;
  integrationProvider: IntegrationProviderDefinitionType;
  integrationProviders: Array<IntegrationProviderDefinitionType>;
  mentions: AtMentionConnection;
  notification?: Maybe<Notification>;
  organization: OrganizationType;
  pql: PqlResponse;
  releaseCommit: Scalars['String']['output'];
  review?: Maybe<Review>;
  reviewTemplates: Array<ReviewTemplateGqlType>;
  salaries: Array<DeveloperSalaryType>;
  samlSpSettings: SpConfigType;
  scopingRule?: Maybe<ScopingRule>;
  search: SearchResultConnection;
  searchOptions: SearchOptionsType;
  teamUsingSleuth: Scalars['Boolean']['output'];
  user?: Maybe<User>;
  widget?: Maybe<Widget>;
  widgetType: WidgetType;
  widgetTypes: WidgetTypeConnection;
  workspace: Workspace;
  workspaces: WorkspaceConnection;
};


export type QueryChoresArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryIntegrationProviderArgs = {
  provider: IntegrationProvider;
};


export type QueryIntegrationProvidersArgs = {
  providerCategories?: InputMaybe<Array<InputMaybe<ProviderCategory>>>;
};


export type QueryMentionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryNotificationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPqlArgs = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  ignoreCache?: InputMaybe<Scalars['Boolean']['input']>;
  query: Scalars['String']['input'];
  scopingRules?: InputMaybe<Array<InputMaybe<ScopingFilterField>>>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};


export type QueryReviewArgs = {
  id: Scalars['ID']['input'];
};


export type QueryReviewTemplatesArgs = {
  reviewType?: InputMaybe<ReviewType>;
};


export type QueryScopingRuleArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  resultType?: InputMaybe<SearchResultType>;
  term?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchOptionsArgs = {
  orgSlug: Scalars['ID']['input'];
};


export type QueryTeamUsingSleuthArgs = {
  email: Scalars['String']['input'];
};


export type QueryWidgetArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWidgetTypeArgs = {
  slug: Scalars['ID']['input'];
};


export type QueryWidgetTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryWorkspaceArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  isGlobal?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryWorkspacesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  globalOnly?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryFilter = {
  __typename?: 'QueryFilter';
  name?: Maybe<Scalars['String']['output']>;
};

export enum Rating {
  /** Good */
  Good = 'GOOD',
  /** Perfect */
  Perfect = 'PERFECT',
  /** So-so */
  SoSo = 'SO_SO'
}

export type RatingTableWidgetCell = {
  __typename?: 'RatingTableWidgetCell';
  rating?: Maybe<Rating>;
  value?: Maybe<Scalars['FloatRoundedTo2']['output']>;
};

export type RatingTableWidgetValue = WidgetValue & {
  __typename?: 'RatingTableWidgetValue';
  cells: Array<Array<Maybe<RatingTableWidgetCell>>>;
  exportLinks: Array<Maybe<ExportLink>>;
  headings: Array<Scalars['String']['output']>;
  legend?: Maybe<WidgetLegend>;
  notes?: Maybe<Array<BaseNoteGqlType>>;
  participationRates?: Maybe<Array<Scalars['FloatRoundedTo2']['output']>>;
  rating?: Maybe<Rating>;
  rows: Array<Scalars['String']['output']>;
  type: WidgetValueFormat;
};

export type RatingWidgetValue = WidgetValue & {
  __typename?: 'RatingWidgetValue';
  commentary?: Maybe<TextContent>;
  exportLinks: Array<Maybe<ExportLink>>;
  legend?: Maybe<WidgetLegend>;
  notes?: Maybe<Array<BaseNoteGqlType>>;
  previousRating?: Maybe<Rating>;
  rating?: Maybe<Rating>;
  type: WidgetValueFormat;
};

export type Reaction = {
  __typename?: 'Reaction';
  author?: Maybe<User>;
  emoji: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export enum RemoteObjectSyncType {
  /** Build Definitions */
  BuildDefinitions = 'BUILD_DEFINITIONS',
  /** Issue State Definitions */
  IssueStateDefinitions = 'ISSUE_STATE_DEFINITIONS'
}

export type RemoveTeamMemberInput = {
  memberId: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
};

export type RemoveTeamMemberMutation = {
  __typename?: 'RemoveTeamMemberMutation';
  errors: Array<ErrorType>;
  team?: Maybe<Team>;
};

export type RemoveTeamsOrUsersFromScopeMutation = {
  __typename?: 'RemoveTeamsOrUsersFromScopeMutation';
  errors?: Maybe<Array<ErrorType>>;
  instance: TeamOrUsersScoped;
};

export type RemoveTeamsOrUsersFromScopeMutationInput = {
  id: Scalars['ID']['input'];
  /** List of TeamOrUser IDs to be removed */
  teamOrUserIds: Array<Scalars['ID']['input']>;
};

export type RemoveUsersFromScopeMutation = {
  __typename?: 'RemoveUsersFromScopeMutation';
  errors?: Maybe<Array<ErrorType>>;
  instance: UserScoped;
};

export type RemoveUsersFromScopeMutationInput = {
  id: Scalars['ID']['input'];
  /** List of user IDs to be removed */
  users: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type RepeatReviewInput = {
  fromReview: Scalars['ID']['input'];
  /** The title of the new review. Defaults to the title of the previous review. */
  newTitle?: InputMaybe<Scalars['String']['input']>;
};

/** Create a new review in this chain of reviews. The new review will link to the previous review and the previous review will link to the new review. */
export type RepeatReviewMutation = {
  __typename?: 'RepeatReviewMutation';
  errors?: Maybe<Array<ErrorType>>;
  review?: Maybe<Review>;
};

/** An enumeration. */
export enum RequestedAuthContextComparison {
  Exact = 'EXACT',
  Password = 'PASSWORD',
  PasswordProtected = 'PASSWORD_PROTECTED',
  X509 = 'X509'
}

export type ResendEmailConfirmationMutation = {
  __typename?: 'ResendEmailConfirmationMutation';
  success: Scalars['Boolean']['output'];
};

export type ResendInvitationMutationInput = {
  invitationId: Scalars['ID']['input'];
};

export type ResendInvitationMutationPayload = {
  __typename?: 'ResendInvitationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  success: Scalars['Boolean']['output'];
};

export type Review = Assessable & Commentable & TeamOrUsersScoped & UserScoped & {
  __typename?: 'Review';
  assessment: DelayedAssessment;
  chain?: Maybe<ReviewChain>;
  comment?: Maybe<Comment>;
  commentAuthors: UserConnection;
  comments: CommentConnection;
  createdOn: Scalars['DateTime']['output'];
  dueOn: Scalars['DateTime']['output'];
  end: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  inProgressOn?: Maybe<Scalars['DateTime']['output']>;
  modifiedOn: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  nextReview?: Maybe<Review>;
  onlyOwnerCanChangeStatus: Scalars['Boolean']['output'];
  owner?: Maybe<User>;
  pql: PqlResponse;
  previousReview?: Maybe<Review>;
  publicShareUrl?: Maybe<Scalars['String']['output']>;
  publishedOn?: Maybe<Scalars['DateTime']['output']>;
  recipientsCount: Scalars['Int']['output'];
  requireApproval: Scalars['Boolean']['output'];
  reviewType: ReviewType;
  section?: Maybe<Section>;
  sections: SectionConnection;
  start: Scalars['DateTime']['output'];
  status: ReviewStatus;
  teamsOrUsers: TeamOrUsersConnection;
  users: UserConnection;
  workspace: Workspace;
};


export type ReviewCommentArgs = {
  id: Scalars['ID']['input'];
};


export type ReviewCommentAuthorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ReviewCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ReviewPqlArgs = {
  query: Scalars['String']['input'];
};


export type ReviewSectionArgs = {
  id: Scalars['ID']['input'];
};


export type ReviewSectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ReviewTeamsOrUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  excludeId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
};


export type ReviewUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type ReviewChain = {
  __typename?: 'ReviewChain';
  chainReviews: ReviewConnection;
  id: Scalars['ID']['output'];
  templateIconPath?: Maybe<Scalars['String']['output']>;
};


export type ReviewChainChainReviewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  includeTopLink?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ReviewConnection = {
  __typename?: 'ReviewConnection';
  edges: Array<ReviewEdge>;
  nodes: Array<Review>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type ReviewEdge = {
  __typename?: 'ReviewEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Review;
};

export enum ReviewStatus {
  /** Draft */
  Draft = 'DRAFT',
  /** In progress */
  InProgress = 'IN_PROGRESS',
  /** Published */
  Published = 'PUBLISHED'
}

export type ReviewTemplateGqlType = {
  __typename?: 'ReviewTemplateGqlType';
  description: Scalars['String']['output'];
  duration: Scalars['Int']['output'];
  emojiDepiction?: Maybe<Scalars['String']['output']>;
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export enum ReviewType {
  /** Review */
  Review = 'REVIEW',
  /** Survey */
  Survey = 'SURVEY'
}

export type RevokeInvitationMutationInput = {
  invitationId: Scalars['ID']['input'];
};

export type RevokeInvitationMutationPayload = {
  __typename?: 'RevokeInvitationMutationPayload';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  success: Scalars['Boolean']['output'];
};

export enum Role {
  Admin = 'ADMIN',
  Billing = 'BILLING',
  Contributor = 'CONTRIBUTOR',
  Dev = 'DEV',
  Member = 'MEMBER'
}

export type SpConfigType = {
  __typename?: 'SPConfigType';
  attributes: Array<Scalars['String']['output']>;
  defaultRelayState: Scalars['String']['output'];
  entityId: Scalars['String']['output'];
  singleLogoutService: Scalars['String']['output'];
  singleSignOnService: Scalars['String']['output'];
  x509cert: Scalars['String']['output'];
  x509certUrl: Scalars['String']['output'];
};

export enum SalaryLevel {
  /** Senior */
  Senior = 'SENIOR'
}

export type ScopingFilterField = {
  /** Integration Auth ID */
  integrationId: Scalars['Int']['input'];
  /** Scoping filters in format `@<parameter>:<value>`, ie. `@project:'PS'` */
  singleFilter: Scalars['String']['input'];
};

export type ScopingModifierNote = BaseNoteGqlType & {
  __typename?: 'ScopingModifierNote';
  auth: Scalars['String']['output'];
  filters: Array<Maybe<Scalars['String']['output']>>;
  msg: Scalars['String']['output'];
};

export enum ScopingRequiredProviderType {
  /** Jira */
  Jira = 'JIRA',
  /** Jira Datacenter */
  JiraDatacenter = 'JIRA_DATACENTER'
}

export type ScopingRule = {
  __typename?: 'ScopingRule';
  createdOn: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  integrationAuth: IntegrationAuthenticationType;
  label: Scalars['String']['output'];
  modifiedOn: Scalars['DateTime']['output'];
  parameter: ParameterValueOptionType;
  provider: ScopingRequiredProviderType;
  value: Scalars['String']['output'];
  workspace: Workspace;
};

export type ScopingRuleConnection = {
  __typename?: 'ScopingRuleConnection';
  edges: Array<ScopingRuleEdge>;
  nodes: Array<ScopingRule>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type ScopingRuleEdge = {
  __typename?: 'ScopingRuleEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: ScopingRule;
};

export enum Score {
  /** Bad */
  Bad = 'BAD',
  /** Good */
  Good = 'GOOD',
  /** OK */
  Ok = 'OK'
}

export type SearchOptionsType = {
  __typename?: 'SearchOptionsType';
  authors: Array<Scalars['String']['output']>;
  deployments: Array<NameAndSlugSearchOptionType>;
  environments: Array<NameAndSlugSearchOptionType>;
  healthOptions: Array<Scalars['String']['output']>;
  labels: Array<NameAndSlugSearchOptionType>;
  projects: Array<NameAndSlugSearchOptionType>;
  sizeOptions: Array<Scalars['String']['output']>;
  tags: Array<Scalars['String']['output']>;
  teams: Array<NameAndSlugSearchOptionType>;
};

export type SearchResult = {
  __typename?: 'SearchResult';
  artifactId?: Maybe<Scalars['ID']['output']>;
  artifactName?: Maybe<Scalars['String']['output']>;
  chainCount?: Maybe<Scalars['Int']['output']>;
  type: SearchResultType;
  workspaceId: Scalars['ID']['output'];
  workspaceMarker: WorkspaceMarkerField;
  workspaceName: Scalars['String']['output'];
};

export type SearchResultConnection = {
  __typename?: 'SearchResultConnection';
  edges: Array<SearchResultEdge>;
  nodes: Array<SearchResult>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type SearchResultEdge = {
  __typename?: 'SearchResultEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: SearchResult;
};

export enum SearchResultType {
  Review = 'REVIEW',
  Survey = 'SURVEY',
  Workspace = 'WORKSPACE'
}

export type Section = Assessable & Commentable & {
  __typename?: 'Section';
  assessment: DelayedAssessment;
  assessmentScoreIsMandatory: Scalars['Boolean']['output'];
  assessmentSummaryIsMandatory: Scalars['Boolean']['output'];
  comment?: Maybe<Comment>;
  commentAuthors: UserConnection;
  comments: CommentConnection;
  hasAssessment: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  owners: UserConnection;
  parameterOptions: ParameterValueOptionConnection;
  position: Scalars['Int']['output'];
  review: Review;
  status: SectionStatus;
  widget?: Maybe<Widget>;
  widgets: WidgetConnection;
};


export type SectionCommentArgs = {
  id: Scalars['ID']['input'];
};


export type SectionCommentAuthorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type SectionCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type SectionOwnersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type SectionParameterOptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  fieldName: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  precursorValues?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  selectedValues?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  slug: Scalars['String']['input'];
  term?: InputMaybe<Scalars['String']['input']>;
};


export type SectionWidgetArgs = {
  id: Scalars['ID']['input'];
};


export type SectionWidgetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type SectionConnection = {
  __typename?: 'SectionConnection';
  edges: Array<SectionEdge>;
  nodes: Array<Section>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type SectionEdge = {
  __typename?: 'SectionEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Section;
};

export enum SectionStatus {
  /** Approved */
  Approved = 'APPROVED',
  /** Draft */
  Draft = 'DRAFT',
  /** Submitted */
  Submitted = 'SUBMITTED'
}

export type SeenEventType = {
  __typename?: 'SeenEventType';
  name: Scalars['String']['output'];
};

export type SetBulkUserWorkingHoursMutation = {
  __typename?: 'SetBulkUserWorkingHoursMutation';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type SetContributorEmailInput = {
  email: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type SetContributorEmailMutation = {
  __typename?: 'SetContributorEmailMutation';
  contributor?: Maybe<User>;
  errors: Array<ErrorType>;
};

export type SetCurrentUserWorkingHoursMutation = {
  __typename?: 'SetCurrentUserWorkingHoursMutation';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type SetDeveloperSalaryMutation = {
  __typename?: 'SetDeveloperSalaryMutation';
  errors: Array<ErrorType>;
  salary?: Maybe<DeveloperSalaryType>;
  success: Scalars['Boolean']['output'];
};

export type SetIdPConfigMutation = {
  __typename?: 'SetIdPConfigMutation';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** NOTE: If the list of filters is empty, we will return `workPlanning: null`. */
export type SetNamedWorkCategoryMutation = {
  __typename?: 'SetNamedWorkCategoryMutation';
  errors?: Maybe<Array<ErrorType>>;
  success: Scalars['Boolean']['output'];
  workCategories: Array<NamedWorkCategoryGqlType>;
};

/** NOTE: If the list of filters is empty, we will return `workPlanning: null`. */
export type SetNamedWorkPlanningMutation = {
  __typename?: 'SetNamedWorkPlanningMutation';
  errors?: Maybe<Array<ErrorType>>;
  success: Scalars['Boolean']['output'];
  workPlanning: Array<NamedWorkPlanningGqlType>;
};

export type SetOrganizationWorkingHoursMutation = {
  __typename?: 'SetOrganizationWorkingHoursMutation';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type SetReviewStatusInput = {
  id: Scalars['ID']['input'];
  status: ReviewStatus;
};

export type SetReviewStatusMutation = {
  __typename?: 'SetReviewStatusMutation';
  errors?: Maybe<Array<ErrorType>>;
  review?: Maybe<Review>;
};

export type SetScopingRuleInput = {
  label: Scalars['String']['input'];
  parameter: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type SetScopingRulesInput = {
  integrationAuthId: Scalars['ID']['input'];
  rules: Array<SetScopingRuleInput>;
  workspaceId: Scalars['ID']['input'];
};

export type SetScopingRulesMutation = {
  __typename?: 'SetScopingRulesMutation';
  errors?: Maybe<Array<ErrorType>>;
  success: Scalars['Boolean']['output'];
};

export type SetSectionStatusInput = {
  id: Scalars['ID']['input'];
  status: SectionStatus;
};

export type SetSectionStatusMutation = {
  __typename?: 'SetSectionStatusMutation';
  errors?: Maybe<Array<ErrorType>>;
  section?: Maybe<Section>;
};

export enum SettingsPage {
  IntegrationAuthScoping = 'INTEGRATION_AUTH_SCOPING',
  OrgSalaries = 'ORG_SALARIES',
  PqlBuckets = 'PQL_BUCKETS',
  WidgetConfig = 'WIDGET_CONFIG'
}

export type ShareReviewMutation = {
  __typename?: 'ShareReviewMutation';
  errors?: Maybe<Array<ErrorType>>;
  review?: Maybe<Review>;
};

export type ShortTeamType = {
  __typename?: 'ShortTeamType';
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

/** An enumeration. */
export enum SignatureAlgorithm {
  DsaSha1 = 'DSA_SHA1',
  RsaSha1 = 'RSA_SHA1',
  RsaSha256 = 'RSA_SHA256',
  RsaSha384 = 'RSA_SHA384',
  RsaSha512 = 'RSA_SHA512'
}

export enum SocialLoginProviderType {
  /** Github */
  Github = 'GITHUB',
  /** Google */
  Google = 'GOOGLE',
  /** Saml */
  Saml = 'SAML',
  /** Sleuth Dora */
  SleuthDora = 'SLEUTH_DORA'
}

export type SurveyQuestionRatingWidgetValue = WidgetValue & {
  __typename?: 'SurveyQuestionRatingWidgetValue';
  averageRating: Scalars['FloatRoundedTo2']['output'];
  exportLinks: Array<Maybe<ExportLink>>;
  legend?: Maybe<WidgetLegend>;
  notes?: Maybe<Array<BaseNoteGqlType>>;
  responseSummary: Scalars['String']['output'];
  responses: Array<Scalars['String']['output']>;
  type: WidgetValueFormat;
};

export type SurveyQuestionTextWidgetValue = WidgetValue & {
  __typename?: 'SurveyQuestionTextWidgetValue';
  exportLinks: Array<Maybe<ExportLink>>;
  legend?: Maybe<WidgetLegend>;
  notes?: Maybe<Array<BaseNoteGqlType>>;
  responseSummary: Scalars['String']['output'];
  responses: Array<Scalars['String']['output']>;
  type: WidgetValueFormat;
};

export type SyncStatusType = {
  __typename?: 'SyncStatusType';
  integrationAuthSlug?: Maybe<Scalars['String']['output']>;
  lastSyncAt?: Maybe<Scalars['DateTime']['output']>;
  provider: Provider;
  remoteObjectType: RemoteObjectSyncType;
  syncInProgress: Scalars['Boolean']['output'];
};

export type SyncStatusTypeEdge = {
  __typename?: 'SyncStatusTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: SyncStatusType;
};

export type SyncStatusesConnection = {
  __typename?: 'SyncStatusesConnection';
  edges: Array<SyncStatusTypeEdge>;
  nodes: Array<SyncStatusType>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type Team = {
  __typename?: 'Team';
  hasSubteams: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  integrationDescription?: Maybe<Scalars['String']['output']>;
  integrationSlug?: Maybe<Scalars['String']['output']>;
  members: UserConnection;
  name: Scalars['String']['output'];
  parent?: Maybe<Team>;
  provider?: Maybe<IntegrationProvider>;
};


export type TeamMembersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type TeamEdge = {
  __typename?: 'TeamEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Team;
};

export type TeamOrUser = {
  __typename?: 'TeamOrUser';
  avatar: Scalars['String']['output'];
  emailAddress: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: TeamOrUserType;
};

export type TeamOrUserEdge = {
  __typename?: 'TeamOrUserEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: TeamOrUser;
};

export enum TeamOrUserType {
  Team = 'TEAM',
  User = 'USER'
}

export type TeamOrUsersConnection = {
  __typename?: 'TeamOrUsersConnection';
  edges: Array<TeamOrUserEdge>;
  nodes: Array<TeamOrUser>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type TeamOrUsersScoped = {
  teamsOrUsers: TeamOrUsersConnection;
};


export type TeamOrUsersScopedTeamsOrUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  excludeId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type TeamParentInputType = {
  any?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type TeamsConnection = {
  __typename?: 'TeamsConnection';
  edges: Array<TeamEdge>;
  nodes: Array<Team>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type TeamsSyncInputType = {
  /** Slug of the integration used for teams sync in case of multiple integrations configured. */
  integrationSlug?: InputMaybe<Scalars['String']['input']>;
  orgSlug: Scalars['ID']['input'];
  /** Desired Teams provider. Leave empty for manual control over Sleuth Teams */
  provider?: InputMaybe<TeamsSyncProviderType>;
};

export enum TeamsSyncProviderType {
  /** Github */
  Github = 'GITHUB',
  /** Sleuth Dora */
  SleuthDora = 'SLEUTH_DORA'
}

export type TeamsSyncStatusMutation = {
  __typename?: 'TeamsSyncStatusMutation';
  success: Scalars['Boolean']['output'];
};

export type TeamsSyncStatusType = {
  __typename?: 'TeamsSyncStatusType';
  integrationSlug?: Maybe<Scalars['String']['output']>;
  lastSyncAt?: Maybe<Scalars['DateTime']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  syncInProgress: Scalars['Boolean']['output'];
};

export type TextContent = {
  __typename?: 'TextContent';
  html: Scalars['HTMLString']['output'];
  markdown: Scalars['String']['output'];
};

export type TextWidgetValue = WidgetValue & {
  __typename?: 'TextWidgetValue';
  content?: Maybe<TextContent>;
  exportLinks: Array<Maybe<ExportLink>>;
  legend?: Maybe<WidgetLegend>;
  notes?: Maybe<Array<BaseNoteGqlType>>;
  type: WidgetValueFormat;
};

export type ToggleLlmFeaturesMutation = {
  __typename?: 'ToggleLLMFeaturesMutation';
  success: Scalars['Boolean']['output'];
};

export type TrackViewMutation = {
  __typename?: 'TrackViewMutation';
  errors: Array<ErrorType>;
  success: Scalars['Boolean']['output'];
};

export type TriggerIssueStatusesSyncInputType = {
  integrationSlug: Scalars['ID']['input'];
  orgSlug: Scalars['ID']['input'];
};

export type TriggerIssueStatusesSyncMutation = {
  __typename?: 'TriggerIssueStatusesSyncMutation';
  errors: Array<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type TriggerRemoteObjectSyncInputType = {
  integrationSlug: Scalars['ID']['input'];
  orgSlug: Scalars['ID']['input'];
  remoteObjectType: RemoteObjectSyncType;
};

export type TriggerRemoteObjectSyncMutation = {
  __typename?: 'TriggerRemoteObjectSyncMutation';
  errors?: Maybe<Array<ErrorType>>;
  success: Scalars['Boolean']['output'];
};

export type TriggerTeamsSyncInputType = {
  integrationSlugs?: InputMaybe<Array<Scalars['ID']['input']>>;
  orgSlug: Scalars['ID']['input'];
};

export type TriggerTeamsSyncMutation = {
  __typename?: 'TriggerTeamsSyncMutation';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  success: Scalars['Boolean']['output'];
};

export type UnfavoriteWorkspaceMutation = {
  __typename?: 'UnfavoriteWorkspaceMutation';
  errors?: Maybe<Array<ErrorType>>;
  success: Scalars['Boolean']['output'];
};

export enum Unit {
  /** Count */
  Count = 'COUNT',
  /** Percentage */
  Percentage = 'PERCENTAGE',
  /** Seconds */
  Seconds = 'SECONDS',
  /** Unknown */
  Unknown = 'UNKNOWN',
  /** Working hours */
  WorkingHours = 'WORKING_HOURS'
}

export type UnlinkExternalUserMutation = {
  __typename?: 'UnlinkExternalUserMutation';
  errors?: Maybe<Array<ErrorType>>;
  success: Scalars['Boolean']['output'];
};

export type UnshareReviewMutation = {
  __typename?: 'UnshareReviewMutation';
  errors?: Maybe<Array<ErrorType>>;
  review?: Maybe<Review>;
};

export type UpdateOrganizationInputType = {
  addressCity?: InputMaybe<Scalars['String']['input']>;
  addressCountry?: InputMaybe<Scalars['String']['input']>;
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  addressPostalCode?: InputMaybe<Scalars['String']['input']>;
  addressState?: InputMaybe<Scalars['String']['input']>;
  defaultRole?: InputMaybe<Role>;
  description?: InputMaybe<Scalars['String']['input']>;
  emailDomain?: InputMaybe<Scalars['String']['input']>;
  loginMethods?: InputMaybe<Array<LoginMethodInputType>>;
  name?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrganizationMutation = {
  __typename?: 'UpdateOrganizationMutation';
  success: Scalars['Boolean']['output'];
};

export type UpdateReviewInput = {
  dueOn?: InputMaybe<Scalars['DateTime']['input']>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  onlyOwnerCanChangeStatus?: InputMaybe<Scalars['Boolean']['input']>;
  owner?: InputMaybe<Scalars['ID']['input']>;
  requireApproval?: InputMaybe<Scalars['Boolean']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateReviewMutation = {
  __typename?: 'UpdateReviewMutation';
  errors?: Maybe<Array<ErrorType>>;
  review?: Maybe<Review>;
};

export type UpdateSectionInput = {
  assessmentScoreIsMandatory?: InputMaybe<Scalars['Boolean']['input']>;
  assessmentSummaryIsMandatory?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssessment?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  owners?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type UpdateSectionMutation = {
  __typename?: 'UpdateSectionMutation';
  errors?: Maybe<Array<ErrorType>>;
  section?: Maybe<Section>;
};

export type UpdateTeamInput = {
  id: Scalars['ID']['input'];
  members?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateTeamMutation = {
  __typename?: 'UpdateTeamMutation';
  errors: Array<ErrorType>;
  team?: Maybe<Team>;
};

export type UpdateUserMutation = {
  __typename?: 'UpdateUserMutation';
  user?: Maybe<User>;
};

export type UpdateWidgetInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  parameters?: InputMaybe<Scalars['JSONString']['input']>;
  renderer?: InputMaybe<FormatRenderer>;
  size?: InputMaybe<WidgetSize>;
  /** All possible values are: Current month breakdown, Current quarter breakdown, Details, Manual chart, Manual stacked chart, Percentages, Period breakdown, Period vs last period trend, Period vs period last year, Previous comparison, Priorities, Proportions, Rating, Single number, Six period proportions trend, Six period trend, Survey answer list, Text, Trend. But not every widget supports all of them. */
  valueType?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWidgetMutation = {
  __typename?: 'UpdateWidgetMutation';
  errors?: Maybe<Array<ErrorType>>;
  widget?: Maybe<Widget>;
};

export type UpdateWorkspaceMutation = {
  __typename?: 'UpdateWorkspaceMutation';
  errors?: Maybe<Array<ErrorType>>;
  workspace?: Maybe<Workspace>;
};

export type UpdateWorkspaceMutationInput = {
  id: Scalars['ID']['input'];
  marker?: InputMaybe<WorkspaceMarkerInput>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UploadMutation = {
  __typename?: 'UploadMutation';
  errors: Array<ErrorType>;
  urls: Array<Scalars['String']['output']>;
};

export type User = {
  __typename?: 'User';
  dateJoined: Scalars['DateTime']['output'];
  display: Scalars['String']['output'];
  email: Scalars['String']['output'];
  externalUsers: Array<ExternalUserType>;
  favoriteWorkspaces: Array<FavoriteWorkspaceType>;
  firstName: Scalars['String']['output'];
  gravatar: Scalars['String']['output'];
  hasUsablePassword: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean']['output'];
  isBeingImpersonated: Scalars['Boolean']['output'];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean']['output'];
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean']['output'];
  isUserManagedBySaml: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  missingExternalUserProviders: Array<Scalars['String']['output']>;
  notifications: NotificationConnection;
  organizations: Array<OrganizationType>;
  permissions: UserPermissionsType;
  personalTokens: AccessTokenConnection;
  role?: Maybe<Role>;
  seen: Array<SeenEventType>;
  setup: UserSetupType;
  slackUserId?: Maybe<Scalars['String']['output']>;
  teams: Array<ShortTeamType>;
  timezone?: Maybe<Scalars['String']['output']>;
  /** 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String']['output'];
  /** EXPERIMENTAL FIELD, can change at any time. */
  workingHours?: Maybe<WorkingHoursType>;
};


export type UserNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserPersonalTokensArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edges: Array<UserEdge>;
  nodes: Array<User>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: User;
};

export type UserPermissionsType = {
  __typename?: 'UserPermissionsType';
  canChangeBilling: Scalars['Boolean']['output'];
  canChangeOrg: Scalars['Boolean']['output'];
  canChangeOrgConfig: Scalars['Boolean']['output'];
};

export type UserScoped = {
  users: UserConnection;
};


export type UserScopedUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type UserSetupType = {
  __typename?: 'UserSetupType';
  confirmedEmail: Scalars['Boolean']['output'];
  noSlack: Scalars['Boolean']['output'];
  socialConnections: Array<Scalars['String']['output']>;
};

export type UsersModifierNote = BaseNoteGqlType & {
  __typename?: 'UsersModifierNote';
  msg: Scalars['String']['output'];
  teams: Array<Maybe<Scalars['String']['output']>>;
  users: Array<Maybe<Scalars['String']['output']>>;
  usersField: Scalars['String']['output'];
};

export enum UsersType {
  All = 'ALL',
  Contributors = 'CONTRIBUTORS',
  Members = 'MEMBERS'
}

export enum ViewTargetType {
  Review = 'REVIEW',
  Workspace = 'WORKSPACE'
}

/** An enumeration. */
export enum WsEventType {
  CommentAdded = 'comment_added',
  CommentRemoved = 'comment_removed',
  CommentUpdated = 'comment_updated',
  ReviewAssessmentNoChange = 'review_assessment_no_change',
  ReviewAssessmentUpdateRequested = 'review_assessment_update_requested',
  ReviewAssessmentUpdated = 'review_assessment_updated',
  ReviewChainRemoved = 'review_chain_removed',
  ReviewCopied = 'review_copied',
  ReviewCreated = 'review_created',
  ReviewInProgress = 'review_in_progress',
  ReviewPublished = 'review_published',
  ReviewRemoved = 'review_removed',
  ReviewRepeated = 'review_repeated',
  ReviewStopped = 'review_stopped',
  ReviewUpdated = 'review_updated',
  SectionAdded = 'section_added',
  SectionAssessmentNoChange = 'section_assessment_no_change',
  SectionAssessmentUpdateRequested = 'section_assessment_update_requested',
  SectionAssessmentUpdated = 'section_assessment_updated',
  SectionRemoved = 'section_removed',
  SectionStatusUpdated = 'section_status_updated',
  SectionUpdated = 'section_updated',
  WidgetAdded = 'widget_added',
  WidgetAssessmentUpdateRequested = 'widget_assessment_update_requested',
  WidgetAssessmentUpdated = 'widget_assessment_updated',
  WidgetRemoved = 'widget_removed',
  WidgetUpdated = 'widget_updated',
  WidgetValueNoChange = 'widget_value_no_change',
  WidgetValueUpdateRequested = 'widget_value_update_requested',
  WidgetValueUpdated = 'widget_value_updated',
  WorkspaceCreated = 'workspace_created',
  WorkspaceRemoved = 'workspace_removed',
  WorkspaceUpdated = 'workspace_updated'
}

export type Widget = Assessable & TeamOrUsersScoped & {
  __typename?: 'Widget';
  assessment: DelayedAssessment;
  createdOn: Scalars['DateTime']['output'];
  dataModifiedOn?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  modifiedOn: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  parameters: Scalars['JSONString']['output'];
  position: Scalars['Int']['output'];
  renderer: FormatRenderer;
  section: Section;
  size: WidgetSize;
  teamsOrUsers: TeamOrUsersConnection;
  value: DelayedWidgetValue;
  valueType: Scalars['String']['output'];
  widgetType: WidgetType;
};


export type WidgetTeamsOrUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  excludeId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
};


export type WidgetValueArgs = {
  type?: InputMaybe<Scalars['String']['input']>;
};

export type WidgetConnection = {
  __typename?: 'WidgetConnection';
  edges: Array<WidgetEdge>;
  nodes: Array<Widget>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type WidgetEdge = {
  __typename?: 'WidgetEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Widget;
};

export type WidgetError = {
  __typename?: 'WidgetError';
  message: Scalars['String']['output'];
  settingsPage?: Maybe<SettingsPage>;
};

export type WidgetLegend = {
  type?: Maybe<Scalars['String']['output']>;
};

export enum WidgetSize {
  /** Large */
  Large = 'LARGE',
  /** Medium */
  Medium = 'MEDIUM',
  /** Small */
  Small = 'SMALL'
}

export type WidgetType = {
  __typename?: 'WidgetType';
  active: Scalars['Boolean']['output'];
  defaultValues: Scalars['String']['output'];
  description: Scalars['String']['output'];
  filterPqlFragment?: Maybe<Scalars['String']['output']>;
  images: Array<WidgetTypeImage>;
  isSurveyQuestionWidget: Scalars['Boolean']['output'];
  isSurveyWidget: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  namePlaceholder: Scalars['String']['output'];
  parameterSchema: Scalars['String']['output'];
  publishedOn: Scalars['DateTime']['output'];
  slug: Scalars['String']['output'];
  supportedValueTypes: Array<WidgetValueType>;
  tags: Array<Scalars['String']['output']>;
  teamOrUserAware: Scalars['Boolean']['output'];
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  version: Scalars['Int']['output'];
};


export type WidgetTypeDefaultValuesArgs = {
  workspaceId: Scalars['ID']['input'];
};

export type WidgetTypeConnection = {
  __typename?: 'WidgetTypeConnection';
  edges: Array<WidgetTypeEdge>;
  nodes: Array<WidgetType>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type WidgetTypeEdge = {
  __typename?: 'WidgetTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: WidgetType;
};

export type WidgetTypeImage = {
  __typename?: 'WidgetTypeImage';
  description: Scalars['String']['output'];
  isHero: Scalars['Boolean']['output'];
  url: Scalars['String']['output'];
};

export type WidgetValue = {
  exportLinks: Array<Maybe<ExportLink>>;
  legend?: Maybe<WidgetLegend>;
  notes?: Maybe<Array<BaseNoteGqlType>>;
  type: WidgetValueFormat;
};

export enum WidgetValueFormat {
  /** Categories */
  Categories = 'CATEGORIES',
  /** Combined */
  Combined = 'COMBINED',
  /** Grouped categories */
  GroupedCategories = 'GROUPED_CATEGORIES',
  /** Number */
  Number = 'NUMBER',
  /** Percentage categories */
  PercentageCategories = 'PERCENTAGE_CATEGORIES',
  /** Priorities */
  Priorities = 'PRIORITIES',
  /** Rating */
  Rating = 'RATING',
  /** Rating table */
  RatingTable = 'RATING_TABLE',
  /** Rating wth previous */
  RatingWithPrevious = 'RATING_WITH_PREVIOUS',
  /** Rating table */
  SurveyRatingAnswerList = 'SURVEY_RATING_ANSWER_LIST',
  /** Rating table */
  SurveyTextAnswerList = 'SURVEY_TEXT_ANSWER_LIST',
  /** Text */
  Text = 'TEXT'
}

export type WidgetValueType = {
  __typename?: 'WidgetValueType';
  default: Scalars['Boolean']['output'];
  defaultRenderer: FormatRenderer;
  format: WidgetValueFormat;
  formatRenderers: Array<FormatRenderer>;
  name: Scalars['String']['output'];
};

export type WorkEffortLegend = WidgetLegend & {
  __typename?: 'WorkEffortLegend';
  items: Array<WorkEffortLegendItem>;
  type?: Maybe<Scalars['String']['output']>;
};

export type WorkEffortLegendItem = {
  __typename?: 'WorkEffortLegendItem';
  avgHourlySalary?: Maybe<Scalars['DecimalRoundedTo2']['output']>;
  categoryName: Scalars['String']['output'];
  currencySign?: Maybe<Scalars['String']['output']>;
  moneySpent?: Maybe<Scalars['Int']['output']>;
  /** Percentage will be `null` when the total time of all issues is 0. */
  percentage?: Maybe<Scalars['FloatRoundedTo1']['output']>;
  /** Number of seconds spent working on this category. */
  timeSpent: Scalars['Int']['output'];
};

export type WorkingHoursInputType = {
  duration: Scalars['String']['input'];
  recurrence: Scalars['String']['input'];
  startTime: Scalars['Time']['input'];
};

export type WorkingHoursType = {
  __typename?: 'WorkingHoursType';
  duration: Scalars['String']['output'];
  recurrence?: Maybe<Scalars['String']['output']>;
  startTime: Scalars['Time']['output'];
};

export type Workspace = TeamOrUsersScoped & UserScoped & {
  __typename?: 'Workspace';
  chain?: Maybe<ReviewChain>;
  id: Scalars['ID']['output'];
  /** Every org has one global workspace, this workspace cannot be deleted. */
  isGlobal: Scalars['Boolean']['output'];
  marker?: Maybe<WorkspaceMarkerField>;
  name: Scalars['String']['output'];
  parameterOptions: ParameterValueOptionConnection;
  review?: Maybe<Review>;
  reviews: ReviewConnection;
  scopingRules: ScopingRuleConnection;
  teamsOrUsers: TeamOrUsersConnection;
  users: UserConnection;
  workCategoryBuckets?: Maybe<Array<NamedWorkCategoryGqlType>>;
  workPlanningBuckets?: Maybe<Array<NamedWorkPlanningGqlType>>;
};


export type WorkspaceChainArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  reviewId?: InputMaybe<Scalars['ID']['input']>;
};


export type WorkspaceParameterOptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  fieldName: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  includeRelatedTags?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  pqlFragmentName: Scalars['String']['input'];
  precursorValues?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  selectedValues?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  term?: InputMaybe<Scalars['String']['input']>;
};


export type WorkspaceReviewArgs = {
  id: Scalars['ID']['input'];
};


export type WorkspaceReviewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  reviewType?: InputMaybe<ReviewType>;
  term?: InputMaybe<Scalars['String']['input']>;
};


export type WorkspaceScopingRulesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  provider?: InputMaybe<ScopingRequiredProviderType>;
};


export type WorkspaceTeamsOrUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  excludeId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
};


export type WorkspaceUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
};


export type WorkspaceWorkCategoryBucketsArgs = {
  defaultToGlobal?: InputMaybe<Scalars['Boolean']['input']>;
};


export type WorkspaceWorkPlanningBucketsArgs = {
  defaultToGlobal?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WorkspaceConnection = {
  __typename?: 'WorkspaceConnection';
  edges: Array<WorkspaceEdge>;
  nodes: Array<Workspace>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Total count of edges. */
  totalCount: Scalars['Int']['output'];
};

export type WorkspaceEdge = {
  __typename?: 'WorkspaceEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Workspace;
};

export type WorkspaceMarkerField = {
  __typename?: 'WorkspaceMarkerField';
  color?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type?: Maybe<WorkspaceMarkerType>;
};

export type WorkspaceMarkerInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  type: WorkspaceMarkerType;
};

export enum WorkspaceMarkerType {
  /** Emoji */
  Emoji = 'EMOJI',
  /** Icon */
  Icon = 'ICON'
}
