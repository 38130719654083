<script setup lang="ts">
import { useRouteParams } from '@vueuse/router'

const { mainElement } = useAppElementRefs()
const workspaceId = useRouteParams<string>('workspaceId')
const { y } = useScroll(mainElement)
</script>

<template>
  <div class="flex h-full">
    <NuxtLoadingIndicator />
    <ImpersonationWarning />
    <NavigationSearch hidden />
    <slot name="topNav">
      <div
        class="fixed z-20 flex h-[68px] w-screen items-center bg-blue-50 transition-shadow duration-150 ease-in dark:bg-gray-950 print:hidden"
        :class="{ 'shadow-md': y > 0 }"
      >
        <slot name="topNavContent">
          <TopNav v-if="workspaceId" :workspace-id="workspaceId" />
        </slot>
      </div>
    </slot>
    <div class="flex min-h-0 flex-1 dark:bg-gray-900 print:block">
      <div class="flex max-w-64 flex-1 pt-[68px] print:hidden">
        <slot name="reviewLeftSidebar" />
      </div>

      <main
        ref="mainElement"
        class="flex h-full flex-1 flex-col overflow-auto pt-[68px] dark:bg-gray-900 print:h-auto"
      >
        <slot />
      </main>

      <div class="flex w-[17rem] flex-col overflow-y-auto bg-blue-50 pl-4 pt-[68px] dark:bg-gray-900 print:hidden">
        <slot name="rightSidebar" />
      </div>
    </div>
  </div>
</template>
