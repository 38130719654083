import type { Review } from '~/codegen/graphql'
import type { Artifact } from '~/components/artifact/IndexPage.vue'
import { capitalize as capitalizeFn } from 'remeda'
import { ReviewType, Score } from '~/codegen/graphql'
import { RepeatArtifactDocument } from './artifact.generated'

export const REVIEW_FETCHING_STATE_KEY = 'review:fetching'
export const REVIEW_FETCHING_FAILED_STATE_KEY = 'review:fetching:failed'

export function getScoreColor(score: Score) {
  return {
    [Score.Bad]: 'bg-red-500',
    [Score.Ok]: 'bg-yellow-500',
    [Score.Good]: 'bg-green-500',
  }[score]
}

export function getArtifactTitleWithDate(artifact: Pick<Review, 'name' | 'end'>) {
  if (!artifact)
    return ''
  return `${artifact.name} (${toShortDate(artifact.end)})`
}

export async function repeatArtifactAndNavigateToIt(workspaceId: string, id: string, type: ReviewType) {
  const { execute: repeatArtifact } = useMutation(
    RepeatArtifactDocument,
    { clearCacheTags: [`workspace-${workspaceId}-${getArtifactName(type)}s`] },
  )

  const response = await repeatArtifact({ input: { fromReview: id } })
  const newId = response.data?.repeatArtifact?.review?.id

  if (newId) {
    type === ReviewType.Review
      ? navigateTo({
          name: 'teamspace-workspaceId-reviews-reviewId',
          params: { workspaceId, reviewId: newId },
        })
      : navigateTo({
          name: 'teamspace-workspaceId-surveys-surveyId',
          params: { workspaceId, surveyId: newId },
        })
  }

  handleMutationError({ errors: response.data?.repeatArtifact?.errors, combinedError: response.error })
}

export function getArtifactName(type: ReviewType | null, settings?: { capitalize?: boolean, plural?: boolean }): string {
  if (type === null)
    return ''

  let label = type === ReviewType.Review ? 'review' : 'survey'

  if (settings?.capitalize)
    label = capitalizeFn(label)

  if (settings?.plural)
    label += 's'

  return label
}

export function getAlreadySentWarning(type: ReviewType, action: string): string {
  return `Survey has already been sent. To ${action}, move the ${getArtifactName(type)} back to draft first.`
}

export function getDefaultScopeWarning(context: 'survey-sending' | 'widget-scoping'): string {
  const action = context === 'widget-scoping'
    ? 'the data in this widget will be scoped'
    : 'this survey will be sent'

  return `You haven’t selected any teams or users, so ${action} to all the members of the current teamspace.`
}

export function containsSurveyWidgets(artifact: Artifact) {
  return artifact.sections.edges.some(({ node }) =>
    node.widgets.edges.some(({ node }) => node.widgetType.isSurveyQuestionWidget))
}

export type AssessmentScoreBadgeColor = 'green' | 'yellow' | 'red' | 'gray'
export const assessmentScoreOptions: Record<string, { label: string | null, color: AssessmentScoreBadgeColor }> = {
  [Score.Good]: { label: 'Good', color: 'green' },
  [Score.Ok]: { label: 'OK', color: 'yellow' },
  [Score.Bad]: { label: 'At risk', color: 'red' },
  blank: { label: null, color: 'gray' },
}

export function getMetadataForScore(score: Score | null | undefined) {
  return assessmentScoreOptions[score ?? 'blank']
}
