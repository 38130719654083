import { useWebSocket } from '@vueuse/core'

const SECURE_HOSTNAMES = ['app.staging.pulse.sleuth.io', 'app.pulse.sleuth.io']
const wsAutoReconnectOptions = {
  retries: 10,
  delay: 2000,
  onFailed() {
    console.error('Failed to connect WebSocket after 10 retries')
  },
}

export default defineNuxtPlugin(() => {
  const protocol = SECURE_HOSTNAMES.includes(location.hostname) ? 'wss' : 'ws'
  const port = SECURE_HOSTNAMES.includes(location.hostname) ? '' : ':7999'

  let lastKnownId = ''
  const lastWsEvents = ref<WSEvent[]>([])

  const ws = useWebSocket(`${protocol}://${location.hostname}${port}/_api/stream/`, {
    immediate: false,
    // Use this to keep websocket connection alive if needed
    heartbeat: {
      interval: 15000,
      message: 'ping',
      pongTimeout: 5000,
    },
    autoReconnect: wsAutoReconnectOptions,
    onConnected(ws) {
      ws.send(
        JSON.stringify({
          type: 'event_subscription_request',
          since: lastKnownId,
        }),
      )
    },
    onMessage(ws, event) {
      const data = JSON.parse(event.data)
      if (data.id)
        lastKnownId = data.id

      lastWsEvents.value.unshift(data)
      // only keep the last 5 events
      if (lastWsEvents.value.length >= 5)
        lastWsEvents.value.pop()
    },
  })

  return {
    provide: {
      ws,
      lastWsEvents,
      wsAutoReconnectOptions,
    },
  }
})
