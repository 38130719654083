import revive_payload_client_4sVQNw7RlN from "/home/circleci/sleuth/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/circleci/sleuth/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/circleci/sleuth/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/circleci/sleuth/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/circleci/sleuth/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/circleci/sleuth/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/home/circleci/sleuth/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/circleci/sleuth/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_Iz5je2VyIc from "/home/circleci/sleuth/frontend/node_modules/@hexdigital/nuxt-segment/dist/runtime/plugin.client.mjs";
import slideovers_X7whXyrIWR from "/home/circleci/sleuth/frontend/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/home/circleci/sleuth/frontend/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/home/circleci/sleuth/frontend/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/home/circleci/sleuth/frontend/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/home/circleci/sleuth/frontend/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import _0_auth_reLdRJDfWy from "/home/circleci/sleuth/frontend/auth/plugins/0.auth.ts";
import _01_websocket_client_Rng71pft6w from "/home/circleci/sleuth/frontend/plugins/01.websocket.client.ts";
import _02_app_updated_client_6yKiPOiGRC from "/home/circleci/sleuth/frontend/plugins/02.app-updated.client.ts";
import announcekit_client_1s0doFrusN from "/home/circleci/sleuth/frontend/plugins/announcekit.client.js";
import directives_client_23ySUe5jkO from "/home/circleci/sleuth/frontend/plugins/directives.client.ts";
import posthog_client_o9IEgU9LwG from "/home/circleci/sleuth/frontend/plugins/posthog.client.js";
import sentry_client_shVUlIjFLk from "/home/circleci/sleuth/frontend/plugins/sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_Iz5je2VyIc,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  _0_auth_reLdRJDfWy,
  _01_websocket_client_Rng71pft6w,
  _02_app_updated_client_6yKiPOiGRC,
  announcekit_client_1s0doFrusN,
  directives_client_23ySUe5jkO,
  posthog_client_o9IEgU9LwG,
  sentry_client_shVUlIjFLk
]