<script setup lang="ts">
import type { WorkspaceMarkerField } from '~/codegen/graphql'
import { promiseTimeout } from '@vueuse/core'
import ConfirmDialog from '../ConfirmDialog.vue'
import { DeleteWorkspaceDocument, FavoriteWorkspaceDocument, UnfavoriteWorkspaceDocument } from './Workspace.generated'

const props = defineProps<{
  workspaces: { id: string, label: string, marker?: WorkspaceMarkerField | null }[]
  globalWorkspaceId: string
  global: boolean
  favoriteWorkspaces: boolean
}>()

const { canChangeOrgConfig } = useCurrentUser()

const router = useRouter()
const route = useRoute('teamspace-workspaceId')
const currentWorkspaceId = computed(() => route.params.workspaceId)

function getSublinks(wId: string) {
  return [
    { label: 'Reviews', to: `/teamspace/${wId}/reviews`, icon: 'solid:file' },
    { label: 'Surveys', to: `/teamspace/${wId}/surveys`, icon: 'solid:clipboard-check' },
  ]
}

const { execute: deleteWorkspace } = useMutation(DeleteWorkspaceDocument, { refetchTags: ['workspaces'] })
const { execute: favoriteWorkspace } = useMutation(FavoriteWorkspaceDocument, { refetchTags: ['workspaces'] })
const { execute: unfavoriteWorkspace } = useMutation(UnfavoriteWorkspaceDocument, { refetchTags: ['workspaces'] })

const confirmDialog = ref<InstanceType<typeof ConfirmDialog>>()
const toBeDeletedWorkspaceName = ref('')

async function maybeDeleteWorkspace(workspace: { id: string, label: string }) {
  toBeDeletedWorkspaceName.value = workspace.label
  const { isCanceled } = await confirmDialog.value!.reveal()
  if (isCanceled)
    return

  await navigateTo({ name: 'teamspace-workspaceId-reviews', params: { workspaceId: props.globalWorkspaceId } })
  await deleteWorkspace({ id: workspace.id })
}

function items(workspace: { id: string, label: string }) {
  const orgSettings = [
    [
      {
        label: 'Teamspace settings',
        icon: 'light:gear',
        click: () => navigateTo({
          name: 'settings-teamspace-teamspaceId-investment-mix',
          params: { teamspaceId: workspace.id },
        }),
      },
    ],
  ]

  const nonGlobalSettings = [
    [
      {
        label: props.favoriteWorkspaces ? 'Remove from favorites' : 'Add to favorites',
        icon: 'light:star',
        click: () => props.favoriteWorkspaces ? unfavoriteWorkspace({ id: workspace.id }) : favoriteWorkspace({ id: workspace.id }),
      },
      {
        label: 'Teamspace settings',
        icon: 'light:gear',
        click: () => navigateTo({
          name: 'settings-teamspace-teamspaceId',
          params: { teamspaceId: workspace.id },
        }),
      },
    ],
    [{
      label: 'Delete',
      icon: 'light:trash-can',
      click: () => { maybeDeleteWorkspace(workspace) },
    }],
  ]
  return props.global ? orgSettings : nonGlobalSettings
}

function handleAccordionClick(gid: string) {
  const [reviewsLink] = getSublinks(gid)
  router.push(reviewsLink.to)
}

function expanded(workspaceId: string) {
  return currentWorkspaceId.value === workspaceId
}

async function scrollToWorkspace() {
  const workspace = await waitForElement(`[data-active-teamspace="true"]`)
  if (workspace) {
    await promiseTimeout(100)
    workspace.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}

onMounted(() => {
  scrollToWorkspace()
})
</script>

<template>
  <ConfirmDialog ref="confirmDialog" is-delete>
    <div>Are you sure you want to delete '{{ toBeDeletedWorkspaceName }}' teamspace?</div>
  </ConfirmDialog>

  <div class="flex w-full flex-col gap-y-2">
    <div v-for="workspace in workspaces" :key="workspace.id" class="flex flex-col px-2">
      <UButton
        color="white-nav"
        variant="ghost"
        active-class="dark:bg-gray-800 bg-gray-200"
        class="group/navItem flex flex-1 justify-between px-2"
        :data-active-teamspace="expanded(workspace.id)"
        @click="handleAccordionClick(workspace.id)"
      >
        <UTooltip
          :text="workspace.label"
          :prevent="workspace.label.length < 23"
          :popper="{ placement: 'top', offsetDistance: 14 }"
          :open-delay="500"
          class="flex flex-1 items-center gap-x-2 truncate"
        >
          <WorkspaceIcon v-if="workspace.marker" :marker="workspace.marker" class="shrink-0 grow-0" />
          <span class="truncate text-left text-nav font-light text-gray-900 dark:text-white">
            {{ workspace.label }}
          </span>
          <UIcon
            name="solid:caret-right"
            class="shrink-0 grow-0 text-sm text-gray-600 transition-transform duration-200 dark:text-gray-400"
            :class="{ 'rotate-90': expanded(workspace.id) }"
          />
        </UTooltip>
        <OptionsDropdown
          v-if="!global || canChangeOrgConfig"
          :popper="{ offsetDistance: 12, offsetSkid: 6 }"
          :items="items(workspace)"
          :ui="{ width: 'w-48' }"
          name="org"
          @click.stop.prevent
        >
          <OptionsButton
            size="xs"
            color="nav"
            class="invisible group-hover/navItem:visible"
            data-testid="org-dropdown-button"
          />
        </OptionsDropdown>
      </UButton>

      <TransitionHeight :visible="expanded(workspace.id)">
        <UVerticalNavigation
          :links="getSublinks(workspace.id)"
          class="mt-1.5 pl-4"
          :ui="{ padding: 'p-2', size: '!text-nav', font: 'font-light', icon: { base: 'flex-shrink-0 size-4 relative' } }"
        >
          <template #default="{ link, isActive }">
            <div v-if="isActive" class="absolute inset-y-1.5 left-[-5px] w-[3px] rounded-md bg-blue-600" />
            <span class="relative truncate">{{ link.label }}</span>
          </template>
        </UVerticalNavigation>
      </TransitionHeight>
    </div>
  </div>
</template>
